<template>
  <ElCollapse
    v-model="expandedSections"
    class="collapse-form"
  >
    <BaseCollapseSection
      :title="$t('Select Invoices By')"
      name="select-invoices-by"
      class="m-0 collapse-item"
      hasBackground
    >
      <BaseForm
        :saveText="$t('Apply')"
        layout="vertical"
        grid-classes="grid grid-cols-2"
        :loading="loading"
        @submit="applySelection"
        @cancel="applySelection"
      >
        <template #extra-buttons-right>
          <BaseButton
            variant="white"
            class="mr-2 "
            @click="expandedSections = []"
          >
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton
            variant="white"
            class="mr-2 "
            @click="resetModel"
          >
            {{ $t('Reset Filters') }}
          </BaseButton>
        </template>
        <BaseFilterRow :title="$t('Due Date')">
          <BaseDatePicker
            v-model="model.due_date"
            :placeholder="$t('Due Date')"
            :name="$t('Due Date')"
            class="col-span-6"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow :title="$t('Discount Date')">
          <BaseDatePicker
            v-model="model.discount_date"
            :placeholder="$t('Discount Date')"
            :name="$t('Discount Date')"
            class="col-span-6"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow :title="$t('Type')">
          <CostCenterSelect
            v-model="model.type"
            :placeholder="$t('Select Type')"
            :filterOptions="filterInvoiceTypes"
            class="col-span-6"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow :title="$t('Vendor')">
          <VendorSelect
            v-model="model.vendor_id"
            :placeholder="$t('Select Vendor')"
            :hide-label="true"
            class="col-span-6"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow :title="$t('Vendor Cost Type')">
          <CostCenterSelect
            v-model="model.vendor_cost_type"
            :placeholder="$t('Select Vendor Cost Type')"
            class="col-span-6"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Invoice No. Range')"
        >
          <BaseInput
            v-model="model.number_from"
            :placeholder="$t('Invoice No. From')"
            :name="$t('Invoice No. From')"
            type="number"
            class="col-span-3"
            :rules="model.number_to ? 'required' : ''"
            clearable
          />
          <BaseInput
            v-model="model.number_to"
            :placeholder="$t('Invoice No. To')"
            :name="$t('Invoice No. To')"
            type="number"
            class="col-span-3"
            :rules="model.number_from ? 'required' : ''"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow
          class="col-span-full"
        >
          <BaseRadioGroup
            v-model="action"
            class="col-span-full"
            :options="actionOptions"
          />
        </BaseFilterRow>
      </BaseForm>
    </BaseCollapseSection>
  </ElCollapse>
</template>
<script>
import axios from 'axios'
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import CostCenterSelect from '@/components/select/entities/CostCenterSelect.vue';
import VendorSelect from '@/components/select/entities/VendorSelect'
import BaseRadioGroup from '@/components/form/BaseRadioGroup.vue';

export default {
  components: {
    VendorSelect,
    CostCenterSelect,
  },
  data() {
    return {
      loading: false,
      action: 'add',
      model: {
        due_date: null,
        discount_date: null,
        vendor_id: null,
        type: null,
        vendor_cost_type: null,
        number_from: null,
        number_to: null
      },
      actionOptions: [
        {
          label: this.$t('Add To Selection'),
          value: 'add'
        },
        {
          label: this.$t('Remove From Selection'),
          value: 'remove',
          fill: '#f56565'
        }
      ],
      expandedSections: [],
    }
  },
  methods: {
    async applySelection() {
      try {
        this.loading = true
        const { data } = await axios.post('/restify/invoice-payments/actions?action=get-payment-by-filters', this.model)

        this.$emit(`selection:${this.action}`, data)

        this.expandedSections = []
        this.resetModel()
      }
      finally {
        this.loading = false
      }
    },
    filterInvoiceTypes(item) {
      const acceptedTypes = [
        costCenterTypes.GeneralAndAdministrative,
        costCenterTypes.Job
      ]

      return acceptedTypes.includes(item.value)
    },
    resetModel() {
      this.action = 'add'
      this.model = {
        due_date: null,
        discount_date: null,
        vendor_id: null,
        type: null,
        vendor_cost_type: null,
        number_from: null,
        number_to: null
      }
    }
  },
}
</script>
