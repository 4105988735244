<template>
  <EntitySelect
    url="/restify/work-orders"
    :transformData="transformData"
    v-bind="$attrs"
    v-on="$listeners"
    :filter-options="filterOptions"
    :url-params="allUrlParams"
    :name="$attrs.name || $t('Work Order')"
    :placeholder="$attrs.placeholder || $t('Select Work Order')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Work Order')"
    :add-label="$t('Add new Work Order')"
    :id="$attrs.id || 'work-orders-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    :hide-label="hideLabel"
    :value-key="valueKey"
    label-key="number"
    class="w-full"
    :dataFilters="dataFilters"
  />
</template>
<script>
export default {
  name: 'WSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'number',
      }),
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    showStatusColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true
      },
    }
  },
  computed: {
    allUrlParams() {
      if (!this.showStatusColumn) {
        return this.urlParams
      }

      return {
        ...this.urlParams,
        status: this.dataFilters.showActive ? 'open' : null,
      }
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Number'),
          prop: 'attributes.number',
        },
        {
          minWidth: 110,
          maxWidth: 110,
          name: this.$t('Date'),
          prop: 'attributes.date',
          component: 'FormattedDate',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Description'),
          prop: 'attributes.description',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show open Work Orders'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showStatusColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    },
  },
  methods: {
    transformData(data) {
      return data.map((workOrder) => ({
        ...workOrder,
        isInactive: workOrder.attributes.status !== 'open',
      }))
    },
    filterOptions(options) {
      if (!this.excludeIds.length) {
        return options
      }

      return options.filter(o => {
        return !this.excludeIds.includes(o.id)
      })
    },
    formatLabel(workOrder) {
      const { number, description } = workOrder.attributes
      return !description ? number : `${number} (${description})`
    },
  },
}
</script>
