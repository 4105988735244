<template>
  <div class="sm:rounded-md overflow-hidden">
    <SelectInvoicesByFilter
      @selection:add="onAddSelection"
      @selection:remove="onRemoveSelection"
    />
    <AgDataTable
        :url="url"
        :columns="columns"
        :url-params="urlParams"
        :transform-data="mapInvoices"
        :groupDefaultExpanded="1"
        :masterDetail="true"
        :detailCellRendererParams="detailCellRendererParams"
        :groupRowRendererParams="groupRowRendererParams"
        :detailCellRenderer="detailCellRenderer"
        :compact="true"
        :no-borders="true"
        :detailRowAutoHeight="true"
        :per-page="$pagination.list"
        :changePageWithTimeout="true"
        domLayout="autoHeight"
        ref="table"
        actions="search,refresh"
        groupDisplayType="groupRows"
        default-sort="vendors.code"
        @grid-ready="onGridReady"
        @cell-focused="onCellFocused"
    >
      <template #header-info>
        <SummaryPill
            :label="$t('Total to Pay')"
            :value="totalPaidAmount"
        />
      </template>
      <template #additional-actions>
        <div class="flex items-center">
          <base-button
              :disabled="!hasSelections"
              variant="danger-link"
              @click="onCancelSelection"
          >
            {{ $t('Reset Selections') }}
          </base-button>
          <proof-listing-button
              :callback-action="goToProofListing"
              :disabled="!hasSelections"
              path=""
          />
        </div>
      </template>

      <template #extra-actions="{row}">
        <table-view-button
            :link="`/accounts-payable/invoices/${row.id}/view`"
            :skip-focus="true"
        />
        <table-edit-button
            v-if="row.payment_status === paymentStatuses.Special"
            @click="openSpecialSelectionDialog(row)"
        />
      </template>

    </AgDataTable>

    <BaseFormDialog
        v-if="showSelectionDialog"
        :title="$t(`Special selection for invoice #${selectedInvoice.attributes.number}`)"
        :open.sync="showSelectionDialog"
        size="xl"
        @close="closeSpecialSelectionDialog"
    >
      <SpecialSelectionDialog
          :saved-payment="selectedToPay[selectedInvoice?.id]"
          :invoice="selectedInvoice"
          :vendor-total="getVendorPaidAmount(selectedInvoice.attributes.vendor_id)"
          :last-payment-rows="lastPaymentRows"
          @save="onSpecialSelectionSave"
          @cancel="closeSpecialSelectionDialog"
      />
    </BaseFormDialog>
  </div>
</template>

<script lang="ts">
  import Data = API.Data
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { Invoice } from '@/modules/common/types/models'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import { mapInvoices } from '@/modules/accounts-payable/pages/invoices/invoiceUtils'
  import { emptyCheck } from '@/modules/accounts-payable/components/invoice/emptyCheck'
  import JobNumbers from '@/modules/accounts-payable/components/checks/JobNumbers.vue'
  import InvoiceDetails from '@/modules/accounts-payable/pages/invoices/InvoiceDetails.vue'
  import { getPaymentType } from '@/modules/accounts-payable/components/invoice/paymentUtils'
  import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
  import SpecialSelectionDialog from '@/modules/accounts-payable/components/checks/SpecialSelectionDialog.vue'
  import CreatePaymentsRowGroup from '@/modules/accounts-payable/components/payment/CreatePaymentsRowGroup.vue'
  import SelectInvoicesByFilter from '@/modules/accounts-payable/components/payment/SelectInvoicesByFilter.vue'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import {ValueFormatterParams, ValueSetterParams} from '@ag-grid-community/core'
  import get from 'lodash/get'
  import sumBy from 'lodash/sumBy'
  import {paymentStatuses, SpecialSelectionModel} from '@/modules/accounts-payable/components/payment/types'
  import { FreeObject } from '@/modules/common/types/common'
  import { useStorage } from '@vueuse/core'

  const STORAGE_KEY = 'selectedAPInvoicesToPay'

  export default defineComponent({
    components: {
      JobNumbers,
      InvoiceDetails,
      CreatePaymentsRowGroup,
      SpecialSelectionDialog,
      SelectInvoicesByFilter,
    },
    setup() {
      const selectedToPay = useStorage<any>(STORAGE_KEY, {})

      return {
        selectedToPay,
      }
    },
    data() {
      return {
        url: '/restify/invoices',
        detailCellRenderer: null as string | null,
        selectedInvoice: {} as any,
        showSelectionDialog: false,
        paymentStatuses,
        grid: {},
        lastSelectedRow: null,
        lastPaymentRows: [],
      }
    },
    computed: {
      payStatusOptions() {
        return [
          {
            label: this.$t('?'),
            value: null,
          },
          {
            label: this.$t('Pay In Full'),
            value: paymentStatuses.Paid,
          },
          {
            label: this.$t('Special'),
            value: paymentStatuses.Special,
          },
        ]
      },
      urlParams() {
        return {
          related: entityPreviewFields.Vendor,
          status: 'posted,partially_paid',
        }
      },
      columns(): Column[] {
        return [
          {
            headerName: ' ',
            field: 'empty_column',
            minWidth: 30,
            maxWidth: 30,
          },
          {
            headerName: this.$t('Vendor'),
            field: 'attributes.vendor_id',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: ' ',
            field: 'payment_status',
            cellEditor: cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.payStatusOptions,
            },
            valueFormatter: (params: ValueFormatterParams) => {
              const options = get(params, 'colDef.cellEditorParams.options', [])
              const op = options.find((option: { value: any; }) => option.value === params.value)
              return op?.label || ''
            },
            valueSetter: (params: ValueSetterParams) => {
                this.onChangePaymentStatus(params, params.newValue)
                return true
            },
            minWidth: 120,
            maxWidth: 150,
            editable: true,
          },
          {
            field: 'id',
            headerName: ' ',
            cellRenderer: 'agGroupCellRenderer',
            minWidth: 30,
            maxWidth: 30,
            valueGetter: () => '',
          },
          {
            headerName: this.$t('Number'),
            field: 'attributes.number',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}/view',
            minWidth: 100,
            maxWidth: 130,
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Status'),
            field: 'attributes.status',
            maxWidth: 100,
            component: 'StatusLink',
            cellClass: cellClasses.ReadOnlyLight,
            align: 'center',
          },
          {
            headerName: this.$t('Due Date'),
            field: 'attributes.due_date',
            minWidth: 100,
            maxWidth: 180,
            component: 'FormattedDate',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Discount Date'),
            field: 'attributes.discount_date',
            minWidth: 100,
            maxWidth: 180,
            component: 'FormattedDate',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Open Amount'),
            field: 'attributes.open_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: 180,
            align: 'right',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Discount'),
            field: 'attributes.discount_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: 180,
            align: 'right',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Previous Payments'),
            field: 'attributes.paid_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: 180,
            align: 'right',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Current Payment'),
            field: 'attributes.current_paid_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: 180,
            align: 'right',
            cellClass: cellClasses.ReadOnlyLight,
          },
        ]
      },
      hasSelections() {
        if (!this.selectedToPay) {
          return false
        }
        return Object.values(this.selectedToPay).length > 0
      },
      totalPaidAmount() {
        if (!this.selectedToPay) {
          return 0
        }
        const selections = Object.values(this.selectedToPay)
        return sumBy(selections, 'paid_amount')
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'CreatePaymentsRowGroup',
          suppressCount: true,
          wrapText: true,
          autoHeight: true,
          updateGroupInvoicesStatus: (invoices: Data<Invoice>[], status: string | null) => this.updateGroupInvoicesStatus(invoices, status),
          paidStatus: paymentStatuses.Paid,
          selectedToPay: this.selectedToPay,
        }
      },
    },
    methods: {
      detailCellRendererParams(params: any) {
        return {
          parentGrid: this.grid,
          readOnly: true,
          invoice: params.data,
        }
      },
      onGridReady(params: any) {
        this.grid = params
      },
      onCellFocused(params: any) {
        this.lastSelectedRow = params
      },
      async goToProofListing() {
        await this.$router.push('/accounts-payable/payments/proof-listing')
      },
      onChangePaymentStatus(params: any, status: string | null) {
        params.data.loading = true
        let invoice = params.data
        invoice.payment_status = status
        this.updateInvoicesToPay(invoice, status)

        if (status === paymentStatuses.Special) {
          // @ts-ignore
          this.grid.api?.stopEditing()
        }
      },
      async updateGroupInvoicesStatus(invoices: Data<Invoice>[], status: string | null) {
        invoices.forEach(invoice => {
          this.updateInvoicesToPay(invoice, status)
        })
      },
      updateInvoicesToPay(invoice: Data<any>, newStatus: string | null) {
        const { discount_amount, open_amount } = invoice.attributes
        const payment = {
          paid_amount: open_amount,
          vendor_id: invoice.attributes.vendor_id,
          payment_status: newStatus,
          rows: [],
        }

        if (newStatus === paymentStatuses.Paid) {
          const check = {
            id: invoice.id,
            ...emptyCheck,
            amount: open_amount,
            discount_amount,
            type: getPaymentType(invoice?.relationships?.vendor),
          }

          payment.rows = [{...check}] as any

          this.$set(this.selectedToPay, invoice.id, payment)
          this.$set(invoice.attributes, 'current_paid_amount', payment.paid_amount)

        } else if (newStatus === paymentStatuses.NotPaid) {
          this.$delete(this.selectedToPay, invoice.id)
          this.$set(invoice.attributes, 'current_paid_amount', 0)

        } else if (newStatus === paymentStatuses.Special) {

          this.$set(this.selectedToPay, invoice.id, payment)
          this.$set(invoice.attributes, 'current_paid_amount', payment.paid_amount)

          this.openSpecialSelectionDialog(invoice)
        }
      },
      onSpecialSelectionSave(args: SpecialSelectionModel) {
        this.lastPaymentRows = args.model.rows || []
        if (args.invoiceId) {
          this.$set(this.selectedToPay[args.invoiceId], 'paid_amount', args.totalPaid)
          this.$set(this.selectedToPay[args.invoiceId], 'discount_amount', args.totalDiscount)
          this.$set(this.selectedToPay[args.invoiceId], 'rows', args.model.rows)
        }
        if (this.selectedInvoice?.attributes) {
          this.selectedInvoice.attributes.current_paid_amount = args.totalPaid
        }
        this.closeSpecialSelectionDialog()
      },
      async onCancelSelection() {
        const result = await this.$deleteConfirm({
          title: this.$t('Do you really want to cancel the selections ?'),
          description: this.$t('This action will reset all invoice payment statuses to the default state. You will have to process invoice payments again.'),
          buttonText: this.$t('Reset Selections'),
        })

        if (!result) {
          return
        }

        this.clearSelections()
      },
      clearSelections() {
        this.selectedToPay = {}
        this.resetTable()
      },
      openSpecialSelectionDialog(row: any) {
        this.selectedInvoice = row
        this.showSelectionDialog = true
      },
      getVendorPaidAmount(vendorId: string) {
        const vendorInvoices = Object.values(this.selectedToPay).filter(payment => {
          const currentVendorId: string = get(payment, 'vendor_id', '')
          return currentVendorId.toString() === vendorId.toString()
        })
        return sumBy(vendorInvoices, 'paid_amount')
      },
      mapInvoices(invoices: Data<any>[]) {
        const selectedToPay = this.selectedToPay || {}
        return mapInvoices(invoices, selectedToPay)
      },
      closeSpecialSelectionDialog() {
        this.showSelectionDialog = false
        this.startEditingRow()
      },
      startEditingRow() {
        if (this.lastSelectedRow) {
          const rowIndex: number = get(this.lastSelectedRow, 'rowIndex', 0) + 1
          // @ts-ignore
          this.$refs.table.focusOnCell(rowIndex, true)
        }
      },
      async resetTable() {
        // @ts-ignore
        await this.$refs.table.refresh()
        // @ts-ignore
        this.grid.api.redrawRows()
        this.lastSelectedRow = null
      },
      onAddSelection(data: any) {
        data.forEach((invoice: any) => {
          this.updateInvoicesToPay({
            id: invoice.id,
            attributes: {
              ...invoice,
              open_amount: invoice.amount,
            }
          }, paymentStatuses.Paid)
        })

        this.resetTable()
      },
      onRemoveSelection(data: any) {
        data.forEach((invoice: any) => {
          this.updateInvoicesToPay({
            id: invoice.id,
            attributes: {
              ...invoice,
              open_amount: invoice.amount,
            }
          }, paymentStatuses.NotPaid)
        })

        this.resetTable()
      },
    },
    created() {
      this.detailCellRenderer = 'InvoiceDetails'
    },
  })
</script>
