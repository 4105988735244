import axios from 'axios';
import { costCenterTypes, costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";
import Cache from '@/utils/Cache';
import { globalResources } from "@/components/form/util";
import store from "@/store";
import { equipmentTypeWithMaintenance } from "@/enum/enums";
import { nextTick, reactive } from "vue";
import { delay } from "@/utils/utils";

const loading = reactive({})

export const costCenterFields = [
  'cost_center',
  'source_id',
  'type_id',
  'addl_source_id',
]
export const hourFields = [
  'regular_hours',
  'premium_hours',
  'overtime_hours',
]

export async function getDefaultAccountsByPriority(params) {
  await nextTick()

  if (!params.cost_or_income) {
    params.cost_or_income = costOrIncomeTypes.Cost
  }
  const url = '/configuration/accounts'
  const key = JSON.stringify(params) + url

  if (loading[key]) {
    await delay(200)
    return getDefaultAccountsByPriority(params)
  }
  try {
    loading[key] = true
    const cachedData = Cache.get(key)
    if (cachedData) {
      return cachedData
    }
    const { data } = await axios.post(url, params)

    Cache.set(key, data)
    return data
  } finally {
    loading[key] = false
  }
}

export async function getDefaultAccounts(entry) {
  const { cost_center, source_id, type_id, addl_source_id } = entry

  if ([costCenterTypes.Labor, costCenterTypes.Material].includes(cost_center)) {
    return entry
  }
  if (!entry.source_id) {
    return entry
  }

  if (entry.cost_center === costCenterTypes.Job && !entry.addl_source_id) {
    return entry
  }

  if ([costCenterTypes.Equipment, costCenterTypes.WorkOrder].includes(entry.cost_center) && !entry.type_id) {
    return entry
  }

  let cost_or_income

  const jobTypes = store.getters['globalLists/getResourceOptions'](globalResources.JobTypes)
  const jobType = jobTypes.find(jobType => jobType.id === type_id)
  if (jobType?.type) {
    cost_or_income = jobType.type
  }

  const params = {
    cost_center,
    source_id,
    type_id,
    addl_source_id,
    cost_or_income
  }

  const account = await getDefaultAccountsByPriority(params)

  entry.account = account?.account
  entry.subaccount = account?.subaccount

  return entry
}

function getEquipmentTypeIdWithMaintenance() {
  return store.getters['globalLists/getEquipmentTypeIdWithMaintenance']
}

export function onChangeCostCenter(entry) {
  let type_id = ''
  let type_type = ''

  if (entry.cost_center === costCenterTypes.Equipment) {
    type_id = getEquipmentTypeIdWithMaintenance()
    type_type = equipmentTypeWithMaintenance
  }

  return {
    ...entry,
    retention_amount: 0,
    source_id: '',
    source_type: '',
    type_id,
    type_type,
    addl_source_id: '',
    addl_source_type: '',
    account: '',
    subaccount: '',
  }
}

export function getCostCenterFields(routeQuery) {
  const { job_id, equipment_id, work_order_id, material_id } = routeQuery
  let source_id = null
  let cost_center = costCenterTypes.GeneralAndAdministrative
  if (job_id) {
    source_id = job_id
    cost_center = costCenterTypes.Job
  } else if (equipment_id) {
    source_id = equipment_id
    cost_center = costCenterTypes.Equipment
  } else if (work_order_id) {
    source_id = work_order_id
    cost_center = costCenterTypes.WorkOrder
  } else if (material_id) {
    source_id = material_id
    cost_center = costCenterTypes.Material
  }
  return {
    cost_center,
    source_id,
  }
}

export function getAddButtonPath(baseUrl, urlParams = {}) {
  const params = new URLSearchParams();
  const keys = ['vendor_id', 'job_id', 'equipment_id', 'work_order_id', 'material_id']
  keys.forEach(key => {
    if (urlParams[key]) {
      params.append(key, urlParams[key])
    }
  })

  const queryString = params.toString()
  return queryString ? `${baseUrl}?${queryString}` : baseUrl
}

export function hasSpecialUrlParams(routeQuery) {
  const keys = ['vendor_id', 'job_id', 'equipment_id', 'work_order_id', 'material_id']
  return keys.some(key => routeQuery[key])
}
