<template>
  <div>
    <BaseDataTable
        :url="url"
        :columns="columns"
        :url-params="allUrlParams"
        :actions="tableActions"
        :is-expandable="isExpandable"
        :add-entity-in-new-tab="addEntityInNewTab"
        :default-match-filters="defaultMatchFilters"
        :searchPlaceholder="$t('Search invoices...')"
        :group-by.sync="selectedGroupBy"
        :add-text="$t('New invoice')"
        :add-entity-url-query="addEntityUrlQuery"
        :limit-max-height="limitMaxHeight"
        :defaultFilters="!hideAllActions"
        :hide-actions="hideAllActions ? 'add': hideActions"
        :title="title"
        permission="invoices"
        ref="table"
        import-url="/accounts-payable/invoices/import"
        base-entity-path="/accounts-payable/invoices"
        @data-fetch="onDataFetch"
        @meta-fetch="$emit('meta-fetch', $event)"
        @all-selected="onAllSelected"
    >
      <template #additional-actions>
        <void-button
            v-if="actionPermission('voidBulk') && $can('invoices_update')"
            :disabled="voidableSelectedRows.length === 0"
            :loading="voidingProgress"
            size="xs"
            variant="gray-link"
            @click="showVoidDialog = true"
        >
          {{ $t('Void') }}
        </void-button>
        <ProofListingButton
            v-if="actionPermission('postBulk') && $can('invoices_update') && showProofButton"
            :disabled="postableSelectedRows.length === 0"
            :selected-rows="postableSelectedRows"
            path="/accounts-payable/invoices/proof-listing"
        />
      </template>
      <template #select="{row}">
        <base-checkbox
            v-model="row.selected"
            :disabled="isRowSelectionDisabled(row)"
        />
      </template>
      <template #attributes.number="{row}">
        <div class="flex items-center">
          <postable-tooltip v-if="invoiceStatus === resourceStatuses.Pending" :is-postable="isPostable(row)"/>
          <router-link :to="`/accounts-payable/invoices/${row.id}/view`">
            {{ row.attributes.number }}
          </router-link>
        </div>
      </template>
      <template #attributes.status="{row}">
        <invoice-status-popover
            :invoice="row"
            resource-name="invoices"
            @status-change="onInvoiceStatusChange(row, $event)"
        />
      </template>
      <template #extra-actions="{row, index}">
        <slot
          name="extra-actions"
          :row="row"
          :index="index"
        >
          <InvoiceQuickPay
            v-if="$isAuthorized('authorizedToQuickPay', row) && $can('invoices_update') && !hideAllActions"
            :invoice="row"
            @save-success="refreshData"
          />
          <ProofListingButton
            v-if="$isAuthorized('authorizedToPost', row) && $can('invoices_update') && !hideAllActions"
            :selected-rows="[row]"
            path="/accounts-payable/invoices/proof-listing"
          />
          <VoidAction
            v-if="$isAuthorized('authorizedToVoid', row) && $can('invoices_update') && !hideAllActions"
            :id="row.id"
            variant="gray-link"
            size="xs"
            @on-action-callback="refreshData"
          />
        </slot>
      </template>
      <template #expand-content="{row}">
        <InvoiceDetails :invoice="row" class="p-4"/>
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showVoidDialog"
                    :title="$t('Void selected billing(s)?')"
                    :open.sync="showVoidDialog"
                    @close="onCancelBulkAction">
      <BaseVoidDialog @cancel="onCancelBulkAction"
                      @save="onVoid"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses } from '@/enum/enums'
  import { groupByType } from '@/components/table/utils/groupByTypes'
  import TableRowSelect from '@/components/common/checkbox/TableRowSelect'
  import InvoiceDetails from '@/modules/accounts-payable/pages/invoices/InvoiceDetails'
  import { TABLE_ACTIONS, TABLE_COLUMNS, TABLE_URL_PARAMS } from '@/modules/accounts-payable/enum/invoice'
  import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
  import { getAddButtonPath } from "@/modules/common/util/costCenterUtils";

  export default {
    components: {
      InvoiceDetails,
      TableRowSelect,
    },
    props: {
      defaultMatch: {
        type: Boolean,
        default: true,
      },
      invoiceStatus: {
        type: String,
        default: resourceStatuses.Prepaid,
      },
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      limitMaxHeight: {
        type: Boolean,
        default: true,
      },
      hideAllActions: {
        type: Boolean,
        default: false,
      },
      hideActions: {
        type: String,
        default: ''
      },
      title: {
        type: String,
      },
      showSelectColumn: {
        type: Boolean,
        default: true,
      },
      showProofButton: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        voidingProgress: false,
        showVoidDialog: false,
        resourceStatuses,
        isExpandable: true,
        addEntityInNewTab: true,
        canCloneInvoices: false,
        url: '/restify/invoices',
        invoices: [],
        selectedGroupBy: '',
      }
    },
    computed: {
      selectedRows() {
        return this.invoices.filter(e => e.selected)
      },
      voidableSelectedRows() {
        return this.selectedRows.filter(row => this.$isAuthorized('authorizedToVoid', row))
      },
      postableSelectedRows() {
        return this.selectedRows.filter(row => this.$isAuthorized('authorizedToPost', row))
      },
      addEntityUrlQuery() {
        const baseUrl = '/accounts-payable/invoices/add'
        return getAddButtonPath(baseUrl, this.urlParams)
      },
      defaultMatchFilters() {
        if (!this.defaultMatch) {
          return {}
        }

        return {
          ...this.createByMeFilter,
          ...this.$route.query,
        }
      },
      allUrlParams() {
        const params = TABLE_URL_PARAMS[this.invoiceStatus] || {}
        return {
          related: entityPreviewFields.Vendor,
          ...params,
          ...this.urlParams,
        }
      },
      columns() {
        let columns = TABLE_COLUMNS[this.invoiceStatus]
        if (!this.showSelectColumn) {
          columns = columns.filter(column => column.prop !== 'select')
        }
        return this.urlParams?.vendor_id ? columns.filter(column => column.prop !== 'vendors.code') : columns
      },
      tableActions() {
        if (this.hideAllActions) {
          return ''
        }
        let baseActions = 'search,refresh,print'
        if (this.$can('invoices_store')) {
          baseActions += ',add,import'
        }
        return baseActions
      },
      filteredByVendor() {
        return !!this.urlParams?.vendor_id
      },
    },
    methods: {
      onInvoiceStatusChange(invoice, updatedInvoice) {
        const status = updatedInvoice?.attributes?.status
        if (!status) {
          return
        }
        this.$set(invoice.attributes, 'status', status)
      },
      actionPermission(action) {
        return TABLE_ACTIONS[this.invoiceStatus].includes(action)
      },
      async onVoid(data) {
        try {
          this.voidingProgress = true
          this.showVoidDialog = false
          const repositories = this.selectedRows.map(row => row.id)

          const payload = {
            repositories,
            ...data,
          }

          await axios.post('/restify/invoices/actions?action=void-invoices', payload)

        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not void invoice(s)'))
        } finally {
          this.voidingProgress = false
          this.refreshData()
        }
      },
      refreshData() {
        this.allPagesSelected = false
        this.$refs.table?.refresh()
      },
      onCancelBulkAction() {
        this.showVoidDialog = false
        this.refreshData()
      },
      onAllSelected(value) {
        this.allPagesSelected = value
      },
      onDataFetch(invoices) {
        this.invoices = invoices
        this.$emit('data-fetch', invoices)
      },
      isPostable(row) {
        if (row.header) {
          return false
        }
        const { is_postable, is_voidable, is_in_balance } = row?.attributes || {}
        return (is_postable || is_voidable) && is_in_balance
      },
      isRowSelectionDisabled(row) {
        const { is_postable, is_voidable } = row.attributes
        return !is_postable && !is_voidable
      }
    },
    watch: {
      filteredByVendor: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue === oldValue) {
            return
          }

          this.selectedGroupBy = newValue ? '' : groupByType.Vendor
          this.refreshData()
        },
      },
    },
  }
</script>
