<template>
  <validation-provider
      v-bind="$attrs"
      :name="$attrs.name || label"
      :data-test="`select-${$attrs.name || label}`"
      v-slot="{ errors }"
  >
    <label v-if="(label || $slots.label) && !$attrs['hide-label']"
           :for="$attrs.id"
           class="block text-sm font-medium leading-5 text-gray-700 truncate">
      <slot name="label">
        <div class="flex items-center">
          <span v-html="label" :class="labelClass"></span>
          <span v-if="isRequired"
                class="text-gray-500">
                *
          </span>
          <base-tooltip v-if="tip"
                        :tabindex="-1"
                        :content="tip">
            <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
          </base-tooltip>
        </div>
      </slot>
    </label>
    <base-input-error :errors="errors"
                      :show-tooltip="inlineErrors">
      <el-select v-bind="$attrs"
                 v-on="listeners"
                 ref="select"
                 :value="computedValue"
                 :multiple="multiple"
                 :collapse-tags="collapseTags"
                 :clearable="clearable"
                 :filterable="filterable"
                 :class="{ 'select-error': errors.length, 'has-table': tableColumns.length }"
                 :popper-class="tableColumns.length ? 'has-table' : ''"
                 default-first-option
                 class="entity-select w-full"
                 @visible-change="onVisibleChange"
      >
        <template #prefix>
          <slot name="prefix"></slot>
        </template>
        <template #empty>
          <slot name="empty"></slot>
        </template>
        <slot>
          <el-option v-if="multiple && filteredOptions.length && allowSelectAll"
                     :label="$t(`Select All`)"
                     :disabled="allValuesSelected"
                     :value="'all'"/>
          <el-option v-if="multiple && filteredOptions.length" :label="$t(`Clear All`)" :disabled="noValueSelected"
                     :value="'none'"/>
          <template v-if="tableColumns.length">
            <SelectColumns
              :columns="tableColumns"
              :resource-name="resourceName"
              :on-sort="onSort"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              :dataFilters="dataFilters"
            />
            <el-option
              v-for="option in filteredOptions"
              :key="option[valueKey]"
              :value="option[valueKey]"
              :disabled="option.disabled"
              :label="labelFormat ? labelFormat(option) : option[labelKey]"
              :class="{
                'opacity-70': option.isInactive
              }"
            >
              <template v-slot:default>
                <slot :row="option">
                  <div class="flex"
                       @mousedown="onOptionClick(option)"
                  >
                      <span v-for="(column, index) in tableColumns"
                            :key="index"
                            :style="{minWidth: `${column.minWidth}px`, maxWidth: `${column.maxWidth}px`}"
                            class="truncate pr-2 capitalize"
                      >
                      <template v-if="column.toFormat">
                        {{ column.toFormat(get(option, column.prop)) }}
                      </template>
                      <template v-else-if="column.component">
                        <component
                            :is="column.component"
                            :column="column"
                            :row="option"
                        />
                      </template>
                      <template v-else>
                        {{ get(option, column.prop) }}
                      </template>
                    </span>
                  </div>
                </slot>
              </template>
            </el-option>
          </template>
          <template v-else>
            <el-option
              v-for="option in filteredOptions"
              :value="option[valueKey]"
              :key="option[valueKey]"
              :label="labelFormat ? labelFormat(option) : option[labelKey]"
              :disabled="option.disabled"
              @mousedown.native="onOptionClick(option)"
              :class="{
                'opacity-70': option.isInactive
              }"
            />
          </template>
        </slot>
      </el-select>
    </base-input-error>
  </validation-provider>
</template>
<script>
  import { Option, Select } from 'element-ui'
  import { HelpCircleIcon } from 'vue-feather-icons'
  import { scrollToSelectedValue } from "@/components/form/selectSelectionUtils";
  import { isReferenceId } from "@/utils/utils";
  import RefreshResourceButton from "@/components/select/RefreshResourceButton.vue";
  import { useSelectSorting } from "@/modules/common/composables/useSelectSorting";
  import SelectColumns from "@/components/form/select/SelectColumns.vue";
  import Status from '@/components/table/cells/Status'
  import FormattedDate from '@/components/table/cells/FormattedDate'
  import FormattedPrice from '@/components/table/cells/FormattedPrice'
  import FormattedPercent from '@/components/table/cells/FormattedPercent'

  export default {
    inheritAttrs: false,
    components: {
      Status,
      FormattedDate,
      FormattedPrice,
      FormattedPercent,
      SelectColumns,
      RefreshResourceButton,
      HelpCircleIcon,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    props: {
      label: String,
      labelClass: {
        type: [String, Object, Array],
        default: ''
      },
      value: [String, Number, Array, Boolean],
      options: {
        type: Array,
        default: () => [],
      },
      inlineErrors: {
        type: Boolean,
        default: false,
      },
      tableColumns: {
        type: Array,
        default: () => [],
      },
      valueKey: {
        type: String,
        default: 'value',
      },
      labelKey: {
        type: String,
        default: 'label',
      },
      tip: {
        type: String,
        default: '',
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      filterable: {
        type: Boolean,
        default: true,
      },
      labelFormat: Function,
      onMapEntry: [Function, Object],
      multiple: Boolean,
      collapseTags: {
        type: Boolean,
        default: false,
      },
      allowSelectAll: {
        type: Boolean,
        default: true,
      },
      resourceName: {
        type: String,
      },
      ownFilter: Function,
      dataFilters: {
        type: Object,
        default: () => ({}),
      },
    },
    setup() {
      const { onSort, sortData, sortDirection, sortBy } = useSelectSorting()
      return {
        onSort,
        sortData,
        sortDirection,
        sortBy,
      }
    },
    data() {
      return {
        searchQuery: '',
      }
    },
    inject: {
      initialSearchQuery: {
        default: '',
      }
    },
    computed: {
      computedValue() {
        if (this.options.length === 0 && isReferenceId(this.value)) {
          return ''
        }
        return this.value
      },
      filteredOptions() {
        let options = this.options
        if (this.ownFilter || this.searchQuery) {
          options = this.ownFilter(this.options, this.searchQuery)
        }

        return this.sortData(options)
      },
      listeners() {
        return {
          ...this.$listeners,
          change: this.onChange,
        }
      },
      isRequired() {
        const rules = this.$attrs.rules
        if (!rules) {
          return false
        }
        return rules?.includes && rules?.includes('required') || rules?.required
      },
      allValuesSelected() {
        return this.value?.length === this.filteredOptions.length
      },
      noValueSelected() {
        return !this.value
      },
    },
    methods: {
      filterMethod(query) {
        this.searchQuery = query
      },
      onVisibleChange(state) {
        if (!state) {
          return this.searchQuery = ''
        }
      },
      onOptionClick(option) {
        // TODO this breaks the multiple select but is needed for the selects inside data grid. Find a proper fix for this...
        if (this.multiple) {
          return
        }
        const value = option[this.valueKey]
        this.onChange(value)
      },
      onChange(value) {
        if (value === undefined) {
          value = null
        }

        if (this.multiple && value.includes('all')) {
          return this.selectAll()
        }

        if (this.multiple && value.includes('none')) {
          return this.selectNone()
        }

        this.emitChange(value)
      },
      emitChange(value) {
        this.$emit('change', value)
        this.$emit('input', value)
        const entityValue = this.findFullValue(value)
        this.$emit('entity-change', entityValue)
        if (!this.onMapEntry) {
          return
        }
        this.onMapEntry(entityValue)
      },
      selectAll() {
        let value = this.filteredOptions.map(option => option[this.valueKey])
        this.$nextTick(() => {
          this.emitChange(value)
        })
      },
      selectNone() {
        const value = this.multiple ? [] : null
        this.$nextTick(() => {
          this.emitChange(value)
        })
      },
      findFullValue(value) {
        return this.filteredOptions.find(entity => entity[this.valueKey]?.toString() === value?.toString())
      },
      initForceSelect() {
        if (this.initialSearchQuery) {
          setTimeout(() => {
            if (!this.$refs.select) {
              return
            }
            this.$refs.select.selectedLabel = this.initialSearchQuery
            this.$refs.select.onInputChange()
          }, 10)
        }
        const input = this.$el.querySelector('.entity-select .el-input')
        input.addEventListener('keydown', event => {
          const selectRef = this.$refs.select
          if (!selectRef || event.key !== 'Tab') {
            return
          }
          const option = selectRef.options[selectRef.hoverIndex]
          if (option) {
            selectRef.handleOptionSelect(option);
          }
        })
      },
    },
    mounted() {
      this.initForceSelect()
      scrollToSelectedValue(this.value, this.$refs.select)
    },
    watch: {
      value(value) {
        scrollToSelectedValue(value, this.$refs.select)
      }
    }
  }
</script>
<style lang="scss">
  .el-select .el-input {
    input {
      @apply truncate;
    }
  }

  .has-table .el-select-dropdown__list {
    padding-top: 30px;
  }

  .has-table .el-select-dropdown__item {
    height: 30px;
    line-height: 30px;
  }
</style>

