<template>
  <div class="mb-10 proof-listing">

    <div>
      <slot name="header"/>
    </div>

    <ProofListingPrerequisitesErrors
        v-if="prerequisitesValidation.prerequisites_has_errors"
        :prerequisitesValidation="prerequisitesValidation"
        @data-fetch="fetchData"
    />

    <template v-else>

      <div class="flex justify-between space-x-2 my-2">
        <div/>
        <div class="flex items-center space-x-2">
          <slot name="header-buttons"/>
          <table-share-button v-if="showShare"/>
          <ReportPrintAction
            v-if="showPrint"
            :printLocal="printLocal"
          />
          <post-button
              v-if="showPost"
              @click="tryPost"
              :disabled="postDisabled"
              size="xs"
              variant="gray-link"
          >
            {{ $t('Post') }}
          </post-button>

          <slot name="header-buttons-after" :post="tryPost"/>
        </div>
      </div>

      <slot name="default" :loading="loading"/>

      <BaseFormDialog
          v-if="showPostDialog"
          :title="$t(`Post ${entityName}`)"
          :append-to-body="true"
          :open.sync="showPostDialog"
          @close="onCancelPost"
      >
        <PostDialog
            :show-period="!isJournalEntity"
            :show-year="!isJournalEntity"
            :loading="postingProgress"
            :resourceName="entityName"
            @save="onPost"
            @cancel="onCancelPost"
        />
      </BaseFormDialog>

    </template>
  </div>


</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import axios from 'axios';
  import pluralize from 'pluralize';
  import PostDialog from '@/components/common/modal/PostDialog.vue';
  import { PrerequisitesHasErrorsStatusCode } from '@/modules/common/components/proof-listing/util'
  import ProofListingPrerequisitesErrors
    from '@/modules/common/components/proof-listing/ProofListingPrerequisitesErrors.vue'
  import {
    ProofListingPostData,
    ProofListingPrerequisitesValidation
  } from "@/modules/common/components/proof-listing/proofListingTypes";
  import { usePostRedirect } from "@/modules/common/components/proof-listing/usePostRedirect";
  import ReportPrintAction from "@/components/table/ReportPrintAction.vue";
  import {refreshGlobalResourcesByModule} from "@/utils/modules";
  import {getCurrentModule} from "@/mixins/globalMixins";

  export default defineComponent({
    components: {
      ReportPrintAction,
      PostDialog,
      ProofListingPrerequisitesErrors,
    },
    props: {
      entity: {
        type: String,
        required: true,
      },
      url: {
        type: String,
        required: true,
      },
      postUrl: {
        type: String,
      },
      idsField: {
        type: String,
      },
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      addPostParams: {
        type: Function,
      },
      showShare: {
        type: Boolean,
        default: true,
      },
      showPrint: {
        type: Boolean,
        default: true,
      },
      showPost: {
        type: Boolean,
        default: true,
      },
      postDisabled: {
        type: Boolean,
        default: false,
      },
      onSuccess: {
        type: Function,
      },
      printLocal: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { handlePostRedirect } = usePostRedirect()
      return {
        handlePostRedirect,
      }
    },
    data() {
      return {
        loading: false,
        postingProgress: false,
        showPostDialog: false,
        shouldPrint: false,
        postParams: null as ProofListingPostData | null,
        prerequisitesValidation: {} as ProofListingPrerequisitesValidation,
      }
    },
    computed: {
      entityIds() {
        const { ids } = this.$route.query

        return typeof ids === 'string' ? ids?.split(',') : ids || []
      },
      entityName() {
        return pluralize(this.entity, this.entityIds.length)
      },
      isJournalEntity() {
        return this.entity === 'journal'
      },
    },
    methods: {
      async fetchData() {
        try {
          this.loading = true
          this.prerequisitesValidation = {}
          const requestData: Record<string, any> = {
            ...this.urlParams,
            fiscal_year: this.activeFiscalYear,
          }
          if (this.idsField) {
            requestData[this.idsField] = this.entityIds
          }
          const { data, status } = await axios.post(this.url, requestData)

          if (status === PrerequisitesHasErrorsStatusCode) {
            return this.prerequisitesValidation = data
          }

          this.$emit('data-fetch', data)
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      },
      onCancelPost() {
        this.showPostDialog = false
      },
      tryPost({ shouldPrint = false }) {
        this.shouldPrint = shouldPrint
        this.showPostDialog = true;
      },
      async onPost(data: ProofListingPostData) {
        try {
          this.postingProgress = true;
          this.postParams = data

          let payload = {
            repositories: this.entityIds,
            fiscal_year: data.fiscal_year,
            period: data.period,
            entered_by: data.entered_by,
            recurring: data.recurring,
          };

          if (this.addPostParams) {
            this.addPostParams(payload)
          }

          const result = await axios.post(this.postUrl, payload);
          this.showPostDialog = false;
          this.postParams.shouldPrint = this.shouldPrint
          this.$emit('success', result, this.postParams)
          await refreshGlobalResourcesByModule(this.$currentModule)

          if (this.onSuccess) {
            this.onSuccess(result, this.postParams)
            this.postParams = null
            return
          }

          this.postParams = null
          if (this.shouldPrint) {
            return
          }
          await this.handlePostRedirect({
            postData: data,
            postResult: result,
          })
        } catch (err: any) {
          if (err.handled) {
            return;
          }
          this.$error(this.$t('Could not post invoices.'));
        } finally {
          this.postingProgress = false;
          this.shouldPrint = false
        }
      },
    },
    created() {
      this.fetchData()
    },
    watch: {
      urlParams: {
        deep: true,
        handler() {
          this.fetchData()
        },
      },
    },
  })
</script>
<style lang="scss">
  .proof-listing {
    .expanded-row table thead th {
      @apply bg-transparent border-none;
    }

    table .expandable-row td {
      @apply border-b-0;
    }

    table td.td-cell {
      vertical-align: initial;
    }

    table td.top-border {
      @apply border-t border-dashed border-gray-300 border-b-0;
    }

    table td.subtotal {
      @apply font-normal text-gray-700;
    }

    ul li {
      list-style-type: circle;
    }
    a {
      @apply text-gray-700;
    }
  }
</style>
