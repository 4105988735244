<template>
  <div>
    <DownloadButton
      variant="gray-light"
      :loading="loading"
      :icon-only="payroll"
      @click="showDialog = true"
    >
      {{ $t('Download checks') }}
    </DownloadButton>

    <BaseFormDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :title="title"
      :appendToBody="true"
    >
      <base-form
        :save-text="$t('Download Checks')"
        :loading="loading"
        :focus-on-first-input="false"
        @cancel="$emit('cancel')"
        layout="modal"
        @submit="onSubmit"
      >
        <base-select
          v-model="model.template"
          :label="$t('Template')"
          :options="checkTemplateOptions"
          :placeholder="$t('Template')"
          class="col-span-6"
        />
        <div class="col-span-6">
          <base-checkbox
            id="print_checks"
            v-model="model.print_checks"
            :label="$t('Print Checks')"
          />
        </div>
        <div class="col-span-6">
          <base-checkbox
            id="print_dds"
            v-model="model.print_dds"
          >
            <template #label>
              {{ $t('Print Direct Deposits') }}
              <template v-if="model.print_dds">
                {{ $t(' (these will be printed after the checks.)') }}
              </template>
            </template>
          </base-checkbox>
        </div>
        <div
          v-if="model.template === CheckTemplates.Detailed"
          class="col-span-6"
        >
          <base-checkbox
            id="include_signature"
            v-model="model.include_signature"
          >
            <template #label>
              {{ $t('Print Signature') }}
              <template v-if="model.include_signature">
                {{ $t(' (if the bank has a signature, the signature will be included on each check)') }}
              </template>
            </template>
          </base-checkbox>
        </div>
        <div class="col-span-6 mb-4">
          <base-checkbox
            id="cut_section"
            v-model="model.flags.cut"
            :label="$t('Show cut section with scissors?')"
          />
        </div>
      </base-form>
    </BaseFormDialog>
    <StimulsoftPrintButton
      ref="stimulsoftPrint"
      :url="`/restify/payroll-batches/${batch.id}/stimulsoft`"
      :url-params="printParams"
      :hide-button="true"
      :transform-data="filterDetailedChecks"
      entity="payroll-batch"
    />
  </div>
</template>
<script lang="ts" setup>
import {PayrollBatch} from "@/modules/common/types/models";
import {computed, PropType, ref} from "vue";
import axios from "axios";
import {downloadFileForEntity, previewFileInNewTab} from "@/modules/common/util/downloadFileLocally";
import i18n from "@/i18n";
import {info, success} from "@/components/common/NotificationPlugin";
import {formatDate} from "@/plugins/dateFormatPlugin";
import {filterPayrollsForPrint} from "@/modules/payroll/utils/payrollUtils";
import store from "@/store";
import Data = API.Data;

const props = defineProps({
  batch: {
    type: Object as PropType<Data<PayrollBatch>>,
    default: () => ({}),
  },
  payroll: {
    type: Object,
  },
  open: {
    type: Boolean,
  }
})

const payrollEndDate = computed(() => {
  return formatDate(props.batch?.attributes?.period_end_date)
})

const loading = ref(false)
const showDialog = ref(false)

const CheckTemplates = {
  Box: 'Box',
  Detailed: 'Detailed',
  Summary: 'Summary',
}

const model = ref({
  template: CheckTemplates.Detailed,
  print_checks: true,
  print_dds: true,
  include_signature: true,
  flags: {
    cut: true,
  } as any
})

const printParams = computed(() => {
  const params: any = {
    print_checks: Number(model.value.print_checks),
    print_dds: Number(model.value.print_dds),
    include_signature: Number(model.value.include_signature),
    hide_payroll_items: Number(model.value.template === CheckTemplates.Summary),
  }
  for (let key in model.value.flags) {
    params[`flags[${key}]`] = model.value.flags[key]
  }
  return params
})

const payrollBatch = computed(() => {
  return store.state.payroll.currentPayrollBatch
})

const checksCount = computed(() => {
  return payrollBatch.value?.attributes?.counts?.normal
})

const directDepositCount = computed(() => {
  return payrollBatch.value?.attributes?.counts?.normal
})

const checkTemplateOptions = computed(() => {
  return [
    {
      label: i18n.t('Summary'),
      value: CheckTemplates.Summary
    },
    {
      label: i18n.t('Detailed'),
      value: CheckTemplates.Detailed
    },
    {
      label: i18n.t('Box (old)'),
      value: CheckTemplates.Box
    },
  ]
})

const title = computed(() => {
  if (!props.payroll) {
    return i18n.t(`Download checks for Payroll Register ${payrollEndDate.value}`)
  }
  return i18n.t(`Download checks for ${props.payroll?.employee?.name}`)
})

function filterDetailedChecks(data: any) {
  if (model.value.template === CheckTemplates.Summary) {
    data.template_settings.hide_payroll_items = true
  } else if (model.value.template === CheckTemplates.Detailed) {
    data.template_settings.hide_payroll_items = false
  }
  if (!props.payroll) {
    return filterPayrollsForPrint(data, model.value)
  }
  const payrolls = data.payrolls.filter((p: any) => p.payroll_id === props.payroll?.payroll?.id) || []
  return {
    ...data,
    payrolls
  }
}

async function onSubmit() {
  if (model.value.template === CheckTemplates.Box) {
    await downloadChecks()
  } else {
    await previewDetailChecks()
  }
}

async function downloadChecks() {
  if (props.payroll) {
    await downloadCheck(props.payroll)
  } else {
    await downloadBatchChecks()
  }
}

async function downloadBatchChecks() {
  try {
    loading.value = true
    info('Downloading checks... Please wait as this may take a while.')
    const url = `/restify/payroll-batches/${props.batch.id}/actions?action=generate-checks`
    const response = await axios.post(url, {
        print_checks: model.value.print_checks,
        print_dds: model.value.print_dds,
        include_signature: model.value.include_signature,
      }, {
        responseType: 'blob',
      }
    )

    previewFileInNewTab(response)
    success(i18n.t('PDF checks downloaded successfully'))
  } catch (err: any) {
    if (err.handled) {
      return
    }
    success(i18n.t('An error occurred while downloading the PDF checks'))
  } finally {
    loading.value = false
  }
}

async function downloadCheck(row: any) {
  try {
    loading.value = true
    const config = {
      template: 'box'
    }
    const employeeCode = row?.employee?.code
    const periodEndDate = row.payroll.period_end_date
    const fileName = `Pay Stub ${employeeCode} ${periodEndDate}.pdf`
    await downloadFileForEntity(row.payroll, 'payrolls', config, fileName)
  } finally {
    loading.value = false
  }
}

const stimulsoftPrint = ref()

async function previewDetailChecks() {
  if (!stimulsoftPrint.value) {
    info(i18n.t('The PDF preview module is still initializing... Please wait a few more seconds and try again.'))
    return
  }
  try {
    loading.value = true
    await stimulsoftPrint.value?.triggerPreview()
  } finally {
    loading.value = false
  }
}

function toggleDialog() {
  showDialog.value = !showDialog.value
}

const emit = defineEmits(['print-ready'])


defineExpose({
  toggleDialog,
})
</script>
