import { $modules, resourceStatuses, transactionTypes } from "@/enum/enums";
import EmptyLayout from "@/components/common/EmptyLayout";
import JobList from "@/modules/job-costing/pages/jobs/job-list";
import JobAdd from "@/modules/job-costing/pages/jobs/job-add";
import JobImport from "@/modules/job-costing/pages/jobs/job-import";
import JobLayout from "@/modules/job-costing/layout/JobLayout";
import JobEdit from "@/modules/job-costing/pages/jobs/job-edit";
import JobDetails from "@/modules/job-costing/pages/jobs/job-details";
import JobActivity from "@/modules/job-costing/pages/jobs/job-activity";
import JobLineItems from "@/modules/job-costing/pages/job-line-items";
import ManageJobLineItems from "@/modules/job-costing/pages/manage-job-line-items";
import LineItemsImport from "@/modules/job-costing/pages/line-items/line-items-import";
import JobRates from "@/modules/job-costing/pages/job-rates";
import LineItems from "@/modules/job-costing/pages/line-items/line-items";
import CostLineItemAdd from "@/modules/job-costing/pages/line-items/cost-line-item-add";
import CostLineItemLayout from "@/modules/job-costing/layout/CostLineItemLayout";
import CostLineItemEdit from "@/modules/job-costing/pages/line-items/cost-line-item-edit";
import CostLineItemDetails from "@/modules/job-costing/pages/line-items/cost-line-item-details";
import CostLineItemProgressUpdate from "@/modules/job-costing/pages/line-items/cost-line-item-progress-update";
import LineItemSummary from "@/modules/job-costing/pages/line-items/line-item-summary";
import LineItemTransactions from "@/modules/job-costing/pages/line-items/line-item-transactions";
import IncomeLineItemAdd from "@/modules/job-costing/pages/line-items/income-line-item-add";
import IncomeLineItemLayout from "@/modules/job-costing/layout/IncomeLineItemLayout";
import IncomeLineItemEdit from "@/modules/job-costing/pages/line-items/income-line-item-edit";
import IncomeLineItemDetails from "@/modules/job-costing/pages/line-items/income-line-item-details";
import IncomeLineItemProgressUpdate from "@/modules/job-costing/pages/line-items/income-line-item-progress-update";
import JobTransactionsLayout from "@/modules/job-costing/layout/JobTransactionsLayout";
import JobTransactionsList from "@/modules/job-costing/pages/job-transactions-list";
import JobTransactionAdd from "@/modules/job-costing/pages/job-transaction-add";
import JobTransactionLayout from "@/modules/job-costing/layout/JobTransactionLayout";
import JobTransactionEdit from "@/modules/job-costing/pages/job-transaction-edit";
import JobTransactionDetails from "@/modules/job-costing/pages/job-transaction-details";
import ProgressUpdatingLayout from "@/modules/job-costing/layout/ProgressUpdatingLayout";
import AllLineItems from "@/modules/job-costing/pages/progress-updating/all-line-items";
import SelectedLineItems from "@/modules/job-costing/pages/progress-updating/selected-line-items";
import Reports from "@/modules/job-costing/layout/Reports";
import JobProfitReport from "@/modules/job-costing/pages/reports/job-profit-report";
import BillingCostComparison from "@/modules/job-costing/pages/reports/billing-cost-comparison";
import DailyJobCostDetailReport from "@/modules/job-costing/pages/reports/daily-job-cost-detail-report";
import JobCostVarianceReport from "@/modules/job-costing/pages/reports/job-cost-variance-report";
import JobCostDetailReport from "@/modules/job-costing/components/reports/job-cost-detail/JobCostDetailReport";
import SettingsLayout from "@/modules/job-costing/layout/SettingsLayout";
import JobSettings from "@/modules/job-costing/pages/settings/job-settings";
import PreNotice from "@/modules/job-costing/pages/settings/pre-notice";
import PreNoticeAdd from "@/modules/job-costing/pages/settings/pre-notice-add";
import PreNoticeEdit from "@/modules/job-costing/pages/settings/pre-notice-edit";
import JobCategoryList from "@/modules/job-costing/pages/settings/job-category-list";
import JobCategoryImport from "@/modules/job-costing/pages/settings/job-category-import";
import JobCategoryAdd from "@/modules/job-costing/pages/settings/job-category-add";
import JobCategoryEdit from "@/modules/job-costing/pages/settings/job-category-edit";
import ReviewJobLayout from "@/modules/job-costing/layout/ReviewJobLayout";
import JobSummaries from "@/modules/job-costing/pages/review-job/job-summaries";
import JCJournals from '@/modules/common/components/journal/journal'
import JCJournalDetails from '@/modules/common/components/journal/journal-details'
import LineItemsLayout from "@/modules/job-costing/layout/LineItemsLayout";
import JobCostInitLayout from "@/modules/job-costing/layout/JobCostInitLayout";
import JobCostInitList from "@/modules/job-costing/pages/settings/job-cost-init/job-cost-init-list";
import JobCostInitAdd from "@/modules/job-costing/pages/settings/job-cost-init/job-cost-init-add";
import JobCostInitEdit from "@/modules/job-costing/pages/settings/job-cost-init/job-cost-init-edit";
import JobCostInitProofListing from "@/modules/job-costing/pages/settings/job-cost-init/job-cost-init-proof-listing";
import JobCostInitImport from "@/modules/job-costing/pages/settings/job-cost-init/job-cost-init-import.vue";
import SettingsActivityLog from "@/modules/common/components/SettingsActivityLog.vue";
import { RestifyResources } from "@/components/form/util";
import JobInvoices from "@/modules/job-costing/pages/jobs/job-invoices.vue";
import JobBillings from "@/modules/job-costing/pages/jobs/job-billings.vue";
import JobPurchaseOrders from "@/modules/job-costing/pages/jobs/job-purchase-orders.vue";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import JCSettingsActivityLayout from "@/modules/job-costing/layout/JCSettingsActivityLayout.vue";
import JobTypesActivityLog from "@/modules/job-costing/pages/settings/job-types-activity-log.vue";
import JobCategoriesActivityLog from "@/modules/job-costing/pages/settings/job-categories-activity-log.vue";
import JobTransactionsProofListing from "@/modules/job-costing/pages/job-transactions-proof-listing.vue";
import JobTimesheets from "@/modules/job-costing/pages/jobs/job-timesheets.vue";
import LineItemTimesheets from "@/modules/job-costing/pages/line-items/line-item-timesheets.vue";
import JobLineItemsLayout from "@/modules/job-costing/layout/JobLineItemsLayout.vue";
import LinkedLineItems from "@/modules/job-costing/pages/linked-line-items.vue";
import PhaseTitlesList from "@/modules/job-costing/pages/phase-titles-list.vue";

export const reportRoutes = [
  {
    path: 'profit-summary',
    name: 'Job Profit Summary',
    title: 'Job Profit Summary',
    meta: {
      permissions: ['job_costing_reports'],
      appScreen: '28.88',
    },
    component: JobProfitReport,
  },
  {
    path: 'billing-cost-comparison',
    name: 'Billing / Cost Comparison',
    title: 'Billing / Cost Comparison',
    meta: {
      permissions: ['job_costing_reports'],
      appScreen: '28.77',
    },
    component: BillingCostComparison,
  },
  {
    path: 'daily-job-cost-detail',
    name: 'Daily Job Cost Detail',
    title: 'Daily Job Cost Detail',
    meta: {
      permissions: ['job_costing_reports'],
      appScreen: '28.71',
    },
    component: DailyJobCostDetailReport,
  },
  {
    path: 'job-cost-detail',
    name: 'Job Cost Detail',
    title: 'Job Cost Detail',
    meta: {
      permissions: ['job_costing_reports'],
      appScreen: '28.81',
    },
    component: JobCostDetailReport,
  },
  {
    path: 'job-cost-variance',
    name: 'Job Cost Variance',
    title: 'Job Cost Variance',
    meta: {
      permissions: ['job_costing_reports'],
      appScreen: '28.86',
    },
    component: JobCostVarianceReport,
  },
]

export default [
  {
    path: '/job-costing',
    name: 'Job Costing ',
    component: EmptyLayout,
    redirect: '/job-costing/jobs',
    meta: {
      isModule: true,
      permissions: ['jobs_show'],
    },
    children: [
      {
        path: 'jobs',
        name: 'Jobs',
        title: 'Jobs',
        meta: {
          permissions: ['jobs_show'],
          appScreen: 'Setup Job Descriptions: 20.10'
        },
        description: 'Access and manage your job costing jobs',
        component: JobList,
      },
      {
        path: 'jobs/add',
        name: 'Add Job',
        title: 'Add Job',
        meta: {
          permissions: ['jobs_store'],
          appScreen: 'Setup Job Descriptions: 20.10',
        },
        description: 'Add Job Descriptions',
        component: JobAdd,
      },
      {
        path: 'jobs/import',
        name: 'Import Jobs',
        title: 'Import Jobs',
        meta: {
          permissions: ['jobs_store'],
        },
        description: 'Import JC Jobs from CSV or Excel files',
        component: JobImport,
      },
      {
        path: 'jobs/:id',
        name: 'Job Details ',
        redirect: '/job-costing/jobs',
        meta: {
          permissions: ['jobs_show'],
        },
        component: JobLayout,
        children: [
          {
            path: 'edit',
            name: 'Job Edit',
            meta: {
              permissions: ['jobs_update'],
              appScreen: 'Setup Job Descriptions: 20.10',
              resourceName: RestifyResources.Jobs,
            },
            component: JobEdit,
          },
          {
            path: 'view',
            name: 'Job Details',
            meta: {
              permissions: ['jobs_show'],
              appScreen: 'Setup Job Descriptions: 20.10',
              resourceName: RestifyResources.Jobs,
            },
            component: JobDetails,
          },
          {
            path: 'invoices',
            name: 'Job Invoices',
            meta: {
              permissions: ['invoices_show'],
              resourceName: RestifyResources.Jobs,
            },
            component: JobInvoices,
          },
          {
            path: 'documents',
            name: 'Job Documents',
            meta: {
              permissions: ['job_documents_show'],
              resourceName: RestifyResources.Jobs,
            },
            component: DocumentsTable,
          },
          {
            path: 'billings',
            name: 'Job Billings',
            meta: {
              permissions: ['invoices_show'],
              resourceName: RestifyResources.Jobs,
            },
            component: JobBillings,
          },
          {
            path: 'purchase-orders',
            name: 'Job Purchase Orders',
            meta: {
              permissions: ['invoices_show'],
              resourceName: RestifyResources.Jobs,
            },
            component: JobPurchaseOrders,
          },
          {
            path: 'timesheets',
            name: 'Job Timesheets',
            meta: {
              permissions: ['timesheets_approve'],
              resourceName: RestifyResources.Jobs,
            },
            component: JobTimesheets,
          },
          {
            path: 'activity',
            name: 'Job Progress Update Activity',
            meta: {
              permissions: ['line_items_show'],
            },
            component: JobActivity,
          },
          {
            path: 'line-items',
            name: 'Job Line Items Overview',
            redirect: '/job-costing/jobs/:id/line-items/list',
            component: JobLineItemsLayout,
            children: [
              {
                path: 'list',
                name: 'Job Line Items',
                meta: {
                  permissions: ['line_items_show'],
                  appScreen: 'Setup Job Line Items: 20.20/20.30',
                  resourceName: RestifyResources.Jobs,
                },
                component: JobLineItems,
              },
              {
                path: 'linked',
                name: 'Job Linked Line Items',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.Jobs,
                },
                component: LinkedLineItems,
              },
              {
                path: 'phase-titles',
                name: 'Job Phase Titles',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.Jobs,
                },
                component: PhaseTitlesList,
              },
              {
                path: 'manage',
                name: 'Manage Job Line Items',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.Jobs,
                },
                component: ManageJobLineItems,
              },
            ]
          },
          {
            path: 'line-items/import',
            name: 'Import Job Income Line Items',
            meta: {
              permissions: ['line_items_update'],
            },
            component: LineItemsImport,
          },
          {
            path: 'rates',
            name: 'Job Line Item Rates',
            meta: {
              permissions: ['jobs_show'],
              appScreen: 'Setup Job Line Items: 20.20/20.30',
              resourceName: RestifyResources.Jobs,
            },
            component: JobRates,
          },
        ],
      },
      {
        path: '/job-costing',
        name: 'Job Costing',
        component: LineItemsLayout,
        redirect: '/job-costing/cost-line-items',
        meta: {
          hideBreadCrumb: true,
          permissions: ['jobs_show'],
        },
        children: [
          {
            path: 'cost-line-items',
            name: 'Cost Line Items',
            title: 'Cost Line Items',
            description: 'Setup and manage Job Cost Line Items',
            meta: {
              permissions: ['line_items_show'],
              appScreen: 'Setup Cost Line Items: 20.20',
            },
            component: LineItems,
            props: {
              type: 'cost',
            },
          },
          {
            path: 'cost-line-items/add',
            name: 'Add Cost Line Items',
            title: 'Add Cost Line Items',
            description: 'Add Job Cost Line Items',
            meta: {
              permissions: ['line_items_store'],
              appScreen: 'Setup Cost Line Items: 20.20',
            },
            component: CostLineItemAdd
          },
          {
            path: 'line-items/import',
            name: 'Import Job Cost Line Items',
            meta: {
              permissions: ['line_items_update'],
            },
            component: LineItemsImport,
          },
          {
            path: 'cost-line-items/:id',
            name: 'Line Items',
            redirect: '/job-costing/cost-line-items',
            meta: {
              permissions: ['line_items_show'],
              resourceName: RestifyResources.LineItems,
            },
            component: CostLineItemLayout,
            children: [
              {
                path: 'edit',
                name: 'Cost Line Item Edit',
                meta: {
                  permissions: ['line_items_update'],
                  appScreen: 'Setup Cost Line Items: 20.20',
                  resourceName: RestifyResources.LineItems,
                },
                component: CostLineItemEdit,
              },
              {
                path: 'view',
                name: 'Cost Line Item Details',
                meta: {
                  permissions: ['line_items_show'],
                  appScreen: 'Setup Cost Line Items: 20.20',
                  resourceName: RestifyResources.LineItems,
                },
                component: CostLineItemDetails,
              },
              {
                path: 'documents',
                name: 'Cost Line Item Documents',
                meta: {
                  permissions: ['line_item_documents_show'],
                  resourceName: RestifyResources.LineItems,
                },
                component: DocumentsTable,
              },
              {
                path: 'update-progress',
                name: 'Update Cost Line Item Progress',
                meta: {
                  permissions: ['line_items_update'],
                  resourceName: RestifyResources.LineItems,
                },
                component: CostLineItemProgressUpdate,
              },
              {
                path: 'summary',
                name: 'Cost Line Item Summary',
                description: 'Cost Line Item Summary',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.LineItems,
                },
                component: LineItemSummary,
              },
              {
                path: 'timesheets',
                name: 'Cost Line Item Timesheets',
                description: 'Cost Line Item Timesheets',
                meta: {
                  permissions: ['timesheets_approve'],
                  resourceName: RestifyResources.LineItems,
                },
                component: LineItemTimesheets,
              },
              {
                path: 'transactions',
                name: 'Cost Line Item Transactions',
                description: 'Cost Line Item Transactions',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.LineItems,
                },
                component: LineItemTransactions,
              },
            ],
          },
          {
            path: 'income-line-items',
            name: 'Income Line Items',
            title: 'Income Line Items',
            description: 'Setup and manage Job Income Line Items',
            meta: {
              permissions: ['line_items_show'],
              appScreen: 'Setup Income Line Items: 20.30',
            },
            component: LineItems,
            props: {
              type: 'income',
            },
          },
          {
            path: 'income-line-items/add',
            name: 'Add Income Line Items',
            title: 'Add Income Line Items',
            description: 'Add Job Income Line Items',
            meta: {
              permissions: ['line_items_show'],
              appScreen: 'Setup Income Line Items: 20.30',
            },
            component: IncomeLineItemAdd,
          },
          {
            path: 'income-line-items/:id',
            name: 'Income Line Items List',
            redirect: '/job-costing/income-line-items',
            meta: {
              permissions: ['line_items_show'],
            },
            component: IncomeLineItemLayout,
            children: [
              {
                path: 'edit',
                name: 'Income Line Item Edit',
                description: 'Income Line Item Edit',
                meta: {
                  permissions: ['line_items_update'],
                  appScreen: 'Setup Income Line Items: 20.30',
                  resourceName: RestifyResources.LineItems,
                },
                component: IncomeLineItemEdit,
              },
              {
                path: 'view',
                name: 'Income Line Item Details',
                description: 'Income Line Item Details',
                meta: {
                  permissions: ['line_items_show'],
                  appScreen: 'Setup Income Line Items: 20.30',
                  resourceName: RestifyResources.LineItems,
                },
                component: IncomeLineItemDetails,
              },
              {
                path: 'documents',
                name: 'Income Line Item Documents',
                meta: {
                  permissions: ['line_item_documents_show'],
                  resourceName: RestifyResources.LineItems,
                },
                component: DocumentsTable,
              },
              {
                path: 'update-progress',
                name: 'Update Income Line Item Progress',
                meta: {
                  permissions: ['line_items_update'],
                  resourceName: RestifyResources.LineItems,
                },
                component: IncomeLineItemProgressUpdate,
              },
              {
                path: 'summary',
                name: 'Income Line Item Summary',
                description: 'Income Line Item Summary',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.LineItems,
                },
                component: LineItemSummary,
              },
              {
                path: 'timesheets',
                name: 'Cost Line Item Timesheets',
                description: 'Cost Line Item Timesheets',
                meta: {
                  permissions: ['timesheets_approve'],
                  resourceName: RestifyResources.LineItems,
                },
                component: LineItemTimesheets,
              },
              {
                path: 'transactions',
                name: 'Income Line Item Transactions',
                description: 'Income Line Item Transactions',
                meta: {
                  permissions: ['line_items_show'],
                  resourceName: RestifyResources.LineItems,
                },
                component: LineItemTransactions,
              },
            ],
          },
        ]
      },
      {
        path: 'transactions/proof-listing',
        name: 'Job Cost Transactions Proof Listing',
        meta: {
          permissions: ['transaction_show'],
          appScreen: 'Job Cost Transactions Proof Listing',
        },
        component: JobTransactionsProofListing,
      },
      {
        path: 'transactions/cost',
        redirect: '/job-costing/transactions/cost/pending',
        description: 'To record job cost journal entries to Job Cost and/or General Ledger',
        meta: {
          permissions: ['transaction_show'],
        },
        component: JobTransactionsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Job Cost Adjustments',
            title: 'Pending Job Cost Adjustments',
            meta: {
              permissions: ['transaction_show'],
              appScreen: 'Job cost transactions: 20.40',
              status: resourceStatuses.Pending,
              type: transactionTypes.Cost,
            },
            component: JobTransactionsList,
          },
          {
            path: 'posted',
            name: 'Posted Job Cost Adjustments',
            title: 'Posted Job Cost Adjustments',
            meta: {
              permissions: ['transaction_show'],
              appScreen: 'Job cost transactions: 20.40',
              status: resourceStatuses.Posted,
              type: transactionTypes.Cost,
            },
            component: JobTransactionsList,
          },
          {
            path: 'add',
            name: 'Add Job Cost Adjustment',
            title: 'Add Job Cost Adjustment',
            description: 'Prepare a job cost adjustment',
            meta: {
              permissions: ['transaction_store'],
              appScreen: 'Job cost transactions: 20.40',
              type: transactionTypes.Cost,
            },
            component: JobTransactionAdd,
          },
          {
            path: ':id',
            name: 'Cost Adjustment',
            redirect: '/job-costing/transactions/cost',
            meta: {
              permissions: ['transaction_update'],
            },
            component: JobTransactionLayout,
            children: [
              {
                path: 'edit',
                name: 'Job Cost Adjustment Edit',
                meta: {
                  permissions: ['transaction_update'],
                  appScreen: 'Job cost transactions: 20.40',
                  type: transactionTypes.Cost,
                },
                component: JobTransactionEdit,
              },
              {
                path: 'view',
                name: 'Job Cost Adjustment Details',
                meta: {
                  permissions: ['transaction_show'],
                  appScreen: 'Job cost transactions: 20.40',
                  type: transactionTypes.Cost,
                },
                component: JobTransactionDetails,
              },
            ]
          },
        ]
      },
      {
        path: 'transactions/income',
        redirect: '/job-costing/transactions/income/pending',
        description: 'To record job income journal entries to Job Cost and/or General Ledger',
        meta: {
          permissions: ['transaction_show'],
        },
        component: JobTransactionsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Job Income Transactions',
            title: 'Pending Job Income Transactions',
            meta: {
              permissions: ['transaction_show'],
              appScreen: 'Job income transactions: 20.50',
              status: resourceStatuses.Pending,
              type: transactionTypes.Income,
            },
            component: JobTransactionsList,
          },
          {
            path: 'posted',
            name: 'Posted Job Income Transactions',
            title: 'Posted Job Income Transactions',
            meta: {
              permissions: ['transaction_show'],
              appScreen: 'Job income transactions: 20.50',
              status: resourceStatuses.Posted,
              type: transactionTypes.Income,
            },
            component: JobTransactionsList,
          },
          {
            path: 'add',
            name: 'Add Job Income Transaction',
            title: 'Add Job Income Transaction',
            description: 'Prepare a job income transaction',
            meta: {
              permissions: ['transaction_store'],
              appScreen: 'Job income transactions: 20.50',
              type: transactionTypes.Income,
            },
            component: JobTransactionAdd,
          },
          {
            path: ':id',
            name: 'Income Transaction',
            redirect: '/job-costing/transactions/income',
            meta: {
              permissions: ['transaction_update'],
            },
            component: JobTransactionLayout,
            children: [
              {
                path: 'edit',
                name: 'Job Income Transaction Edit',
                meta: {
                  permissions: ['transaction_update'],
                  appScreen: 'Job income transactions: 20.50',
                  type: transactionTypes.Income,
                },
                component: JobTransactionEdit,
              },
              {
                path: 'view',
                name: 'Job Income Transaction Details',
                meta: {
                  permissions: ['transaction_show'],
                  appScreen: 'Job income transactions: 20.50',
                  type: transactionTypes.Income,
                },
                component: JobTransactionDetails,
              },
            ]
          },
        ]
      },
      {
        path: 'progress-updating',
        name: 'Progress Updating',
        title: 'Progress Updating',
        redirect: '/job-costing/progress-updating/all-line-items',
        meta: {
          permissions: ['job_costing_reports'],
        },
        component: ProgressUpdatingLayout,
        children: [
          {
            path: 'all-line-items',
            name: 'Progress Updating All Line Items',
            title: 'All Line Items',
            description: 'Update All Line Items With A Job',
            meta: {
              hideBreadCrumb: true,
              permissions: ['job_costing_reports'],
              appScreen: 'Progress Updating: 20.60',
            },
            component: AllLineItems,
          },
          {
            path: 'selected-line-items',
            name: 'Selected Line Items',
            title: 'Selected Line Items',
            description: 'Update Selected Line Items With A Job',
            meta: {
              permissions: ['job_costing_reports'],
              appScreen: 'Progress Updating: 20.60',
            },
            component: SelectedLineItems,
          },
        ],
      },
      {
        path: 'reports',
        name: 'JC Reports',
        redirect: '/job-costing/reports/daily-job-cost-detail',
        meta: {
          permissions: ['job_costing_reports'],
        },
        component: Reports,
        children: reportRoutes
      },
      {
        path: 'settings',
        name: 'JC Settings',
        redirect: '/job-costing/settings/job-cost-settings',
        meta: {
          permissions: ['job_costing_special_tasks_show'],
        },
        component: SettingsLayout,
        children: [
          {
            path: 'job-cost-settings',
            name: 'Job Cost Settings',
            title: 'Job Cost Settings',
            description: 'Define Job Cost Settings',
            meta: {
              permissions: ['job_costing_settings'],
              appScreen: 'Initialize J/C System: 29.91',
            },
            component: JobSettings,
          },
          {
            path: 'activity',
            name: 'JC Settings Activity',
            meta: {
              permissions: ['job_costing_settings'],
              module: $modules.JC,
            },
            component: JCSettingsActivityLayout,
            children: [
              {
                path: 'activity-log',
                name: 'JC Settings Update Activity',
                meta: {
                  permissions: ['job_costing_settings'],
                  module: $modules.JC,
                },
                component: SettingsActivityLog,
              },
              {
                path: 'job-types-activity-log',
                name: 'JC Settings Job Type Activity',
                meta: {
                  permissions: ['job_costing_settings'],
                  module: $modules.JC,
                },
                component: JobTypesActivityLog,
              },
              {
                path: 'job-categories-activity-log',
                name: 'JC Settings Job Categories Activity',
                meta: {
                  permissions: ['job_costing_settings'],
                  module: $modules.JC,
                },
                component: JobCategoriesActivityLog,
              },
            ]
          },
          {
            path: 'pre-notice',
            name: 'Preliminary Notices',
            title: 'Preliminary Notices',
            description: 'Jobs Preliminary Notices',
            meta: {
              permissions: ['job_pre_notices_show'],
              appScreen: 'Job Preliminary Notices: 29.93A',
            },
            component: PreNotice,
          },
          {
            path: 'pre-notice/add',
            name: 'Add Preliminary Notices',
            title: 'Add Preliminary Notices',
            description: 'Setup Preliminary Notices',
            meta: {
              permissions: ['job_pre_notices_store'],
              appScreen: 'Job Preliminary Notices: 29.93A',
            },
            component: PreNoticeAdd,
          },
          {
            path: 'pre-notice/:id/edit',
            name: 'Preliminary Notices Edit',
            meta: {
              permissions: ['job_pre_notices_update'],
              appScreen: 'Job Preliminary Notices: 29.93A',
            },
            component: PreNoticeEdit,
          },
          {
            path: 'pre-notice/:id/view',
            name: 'Preliminary Notices Details',
            meta: {
              permissions: ['job_pre_notices_show'],
              appScreen: 'Job Preliminary Notices: 29.93A',
            },
            component: PreNoticeEdit,
          },
          {
            path: 'job-cost-init',
            name: 'Initialize Job Costs & Retentions',
            title: 'Initialize Job Costs & Retentions',
            meta: {
              permissions: ['job_costing_settings'],
              appScreen: 'Initialize Job Costs & Retentions: 29.94A/29.94B',
            },
            component: JobCostInitList,
          },
          {
            path: 'job-cost-init/add',
            name: 'Add Costs & Retentions',
            title: 'Add Costs & Retentions',
            description: 'Add Costs & Retentions',
            meta: {
              permissions: ['job_costing_settings'],
            },
            component: JobCostInitAdd,
          },
          {
            path: 'job-cost-init/import',
            name: 'Import Costs & Retentions',
            title: 'Import Costs & Retentions',
            meta: {
              permissions: ['job_costing_settings'],
            },
            component: JobCostInitImport,
          },
          {
            path: 'job-cost-init/:id',
            name: 'Costs & Retentions Payable',
            meta: {
              hideBreadCrumb: true,
              permissions: ['job_costing_settings'],
            },
            component: JobCostInitLayout,
            children: [
              {
                path: 'edit',
                title: 'Edit Costs & Retentions',
                meta: {
                  permissions: ['job_costing_settings'],
                },
                component: JobCostInitEdit,
              },
              {
                path: 'proof-listing',
                name: 'Proof Listing',
                title: 'Proof Listing',
                description: 'Proof Listing',
                meta: {
                  permissions: ['job_costing_settings'],
                },
                component: JobCostInitProofListing,
              },
            ],
          },
          {
            path: 'categories',
            name: 'Job Categories',
            title: 'Job Categories',
            description: 'Define Job Category Codes',
            meta: {
              permissions: ['job_categories_show'],
              appScreen: 'Define Job Category Codes: 29.97',
            },
            component: JobCategoryList,
          },
          {
            path: 'categories/import',
            name: 'Import Job Categories',
            meta: {
              permissions: ['job_categories_store'],
            },
            description: 'Import JC Job Categories from CSV or Excel files',
            component: JobCategoryImport,
          },
          {
            path: 'categories/add',
            name: 'Add Job Category',
            title: 'Add Job Category',
            description: 'Add Job Category Codes',
            meta: {
              permissions: ['job_categories_store'],
              appScreen: 'Define Job Category Codes: 29.97',
            },
            component: JobCategoryAdd,
          },
          {
            path: 'categories/:id/edit',
            name: 'Job Category Edit',
            meta: {
              permissions: ['job_categories_update'],
              appScreen: 'Define Job Category Codes: 29.97',
            },
            component: JobCategoryEdit,
          },
          {
            path: 'categories/:id/view',
            name: 'Job Category Details',
            meta: {
              permissions: ['job_categories_show'],
              appScreen: 'Define Job Category Codes: 29.97',
            },
            component: JobCategoryEdit,
          },
        ],
      },
      {
        path: 'review-job',
        name: 'Review Job Status',
        redirect: '/job-costing/review-job/summaries',
        meta: {
          hideBreadCrumb: true,
          permissions: ['job_costing_reports'],
        },
        component: ReviewJobLayout,
        children: [
          {
            path: 'summaries',
            name: 'Review Job Summaries',
            title: 'Job Summaries',
            description: 'Review Job Summaries',
            meta: {
              permissions: ['job_costing_reports'],
              appScreen: 'Review Job Status: 20.70A',
            },
            component: JobSummaries,
          },
          {
            path: 'line-items',
            name: 'Review Job Line Items',
            title: 'Job Line Items',
            description: 'Review Job Line Items',
            meta: {
              permissions: ['job_costing_reports'],
              appScreen: 'Review Job Status: 20.70',
            },
            component: JobLineItems,
          },
        ]
      },
      {
        path: 'journals',
        name: 'JC Journals',
        redirect: 'journals',
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'JC Journals ',
            title: 'JC Journals',
            meta: {
              hideBreadCrumb: true,
              permissions: ['jobs_show'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'job-costing',
            },
            component: JCJournals,
          },
          {
            path: ':id/view',
            name: 'Job Costing Journal Details',
            meta: {
              permissions: ['jobs_show'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'job-costing',
            },
            component: JCJournalDetails,
          },
        ]
      },
    ],
  },
]
