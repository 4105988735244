<template>
  <base-form
      v-bind="$attrs"
      :loading="loading"
      :layout="layout"
      :save-text="$t('Create employee')"
      :update-text="$t('Update employee')"
      class="employee-form"
      grid-classes="grid grid-cols-8 gap-x-4"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <el-collapse
      v-model="activeGroups"
      class="col-span-8"
    >
      <base-collapse-section key="pay_information"
                             :title="$t(`Pay`)"
                             name="pay_information">
        <div class="grid grid-cols-8 gap-x-4">
          <base-select v-model="model.status"
                       :options="statusOptions"
                       :label="$t('Status')"
                       :name="$t('Status')"
                       :placeholder="$t('Status')"
                       id="status"
                       rules="required"
                       class="col-span-8 md:col-span-2 xl:col-span-1"
                       @change="onStatusChange"
          />
          <base-select v-model="model.pay_frequency"
                       :options="payFrequencyOptions"
                       :label="$t('Pay Frequency')"
                       :name="$t('Pay Frequency')"
                       :placeholder="$t('Pay Frequency')"
                       id="pay_frequency"
                       rules="required"
                       class="col-span-8 md:col-span-2 xl:col-span-1"
          />
          <base-input
            v-if="model.status === employeeStatuses.SALARY"
            v-model="model.salary_amount"
            :label="$t('Period Salary Amount')"
            :placeholder="$t('Period Salary Amount')"
            :min="0"
            :step="0.01"
            :max="99999.99"
            format="price"
            id="salary_amount"
            type="number"
            rules="max_value:99999.99|min_value:0"
            class="col-span-8 md:col-span-2"
          />
          <base-input
            v-if="model.status === employeeStatuses.HOURLY"
            v-model="model.hourly_rate"
            :label="$t('Hourly Rate')"
            :placeholder="$t('Hourly Rate')"
            :min="0"
            :step="0.01"
            :max="99999.99"
            :tip="$t('Leave blank and choose Union & Craft codes.')"
            format="price"
            id="hourly_rate"
            type="number"
            rules="max_value:99999.99|min_value:0"
            class="col-span-8 md:col-span-2"
          />
        </div>
        <div class="col-span-8">
          <base-alert
            v-if="showBiWeeklyHourlyWarning"
            type="warning">
            {{
              $t('Payroll is setup bi-weekly. Please make sure the employee pay frequency is setup accordingly to avoid potential issues during payroll calculation.')
            }}
          </base-alert>
          <base-alert
            v-if="showBiWeeklySalaryWarning"
            type="warning">
            {{
              $t(`Payroll is setup bi-weekly. For salary employees it's recommended to use the bi-weekly, semi-monthly or monthly salary amounts to avoid potential issues during payroll calculation.`)
            }}
          </base-alert>
        </div>
      </base-collapse-section>
      <base-collapse-section key="personal_information"
                             :title="$t(`Personal Information`)"
                             name="personal_information">
        <div class="grid grid-cols-8 gap-x-4">
          <base-input v-model="model.first_name"
                      :label="$t('First Name')"
                      :placeholder="$t('First name')"
                      id="first_name"
                      rules="required|max:35"
                      class="col-span-8 md:col-span-2"
                      @blur="onNameBlur"
          />
          <base-input v-model="model.last_name"
                      :label="$t('Last Name')"
                      :placeholder="$t('Last name')"
                      id="last_name"
                      rules="required|max:35"
                      class="col-span-8 md:col-span-2"
                      @blur="onNameBlur"
          />
          <base-input v-model="model.middle_name"
                      :label="$t('Middle Name')"
                      :placeholder="$t('Middle name')"
                      id="middle_name"
                      class="col-span-8 md:col-span-1"
                      @blur="onNameBlur"
          />
          <base-input v-model="model.suffix"
                      :label="$t('Suffix')"
                      :placeholder="$t('Suffix')"
                      id="suffix"
                      class="col-span-8 md:col-span-1"
          />
          <code-input
            v-model="model.code"
            :model="model"
            :resource-name="globalResources.Employees"
            class="col-span-8 md:col-span-1"
          />
          <base-input v-model="model.email"
                      :label="$t('Email')"
                      :placeholder="$t('Employee email')"
                      :tip="$t('This email will be used for receiving checks')"
                      id="email"
                      rules="email"
                      class="col-span-8 md:col-span-2"
          />

          <ssn-input v-model="model.ssn"
                     :placeholder="$t('SSN')"
                     :label="$t('SSN')"
                     :name="$t('SSN')"
                     :read-only="false"
                     id="ssn"
                     rules="required"
                     class="col-span-8 md:col-span-2 xl:col-span-2"
          />
          <base-select v-model="model.sex"
                       :options="sexOptions"
                       :label="$t('Gender')"
                       :name="$t('Gender')"
                       :placeholder="$t('Gender')"
                       id="sex"
                       rulese="required"
                       class="col-span-8 md:col-span-2 xl:col-span-1"
          />
          <base-select v-model="model.minority"
                       :options="minorityOptions"
                       :label="$t('Minority')"
                       :name="$t('Minority')"
                       :placeholder="$t('Minority')"
                       id="minority"
                       rulese="required"
                       class="col-span-8 md:col-span-2 xl:col-span-1"
          />
          <div class="col-span-8 md:col-span-2 xl:col-span-2"></div>
          <state-tax-select
            v-model="model.withholding_state_id"
            :label="$t('Withholding State')"
            :name="$t('Withholding State')"
            :placeholder="$t('Withholding State')"
            :initial-value="get(data, 'relationships.withholdingState')"
            :add-entity="true"
            id="withholding_state_id"
            key="withholding_state_id"
            rules="required"
            class="col-span-8 md:col-span-2 xl:col-span-1"
            @entity-change="onChangeWithholdingState"
          />
          <local-tax-select
            v-model="model.withholding_local_id"
            :label="$t('Local Code')"
            :placeholder="$t('Local Code')"
            :initial-value="get(data, 'relationships.withholdingLocal')"
            id="withholding_local_id"
            class="col-span-8 md:col-span-2 xl:col-span-1"
            clearable
          />
          <state-tax-select
            v-if="showStateSelect"
            v-model="model.sui_sdi_state_id"
            :label="$t('State (SUI/SDI)')"
            :name="$t('State')"
            :placeholder="$t('State')"
            :initial-value="get(data, 'relationships.suiSdiState', suiSdiState)"
            :add-entity="true"
            label-key="sui_code"
            id="sui_sdi_state_id"
            key="sui_sdi_state_id"
            rules="required"
            class="col-span-8 md:col-span-2 xl:col-span-1"
          />
          <account-select v-model="model.account"
                          :label="$t('Normal G/L Account')"
                          :placeholder="$t('Account')"
                          value-key="number"
                          class="col-span-8 md:col-span-2"
                          rules="required"
                          clearable
          />
          <sub-account-select v-model="model.subaccount"
                              :label="$t('Sub Account')"
                              :placeholder="$t('Sub Account')"
                              value-key="number"
                              class="col-span-8 md:col-span-2 lg:col-span-1"
                              clearable
          />

        </div>
      </base-collapse-section>
      <base-collapse-section
        key="contact_information"
        :title="$t(`Contact Information`)"
        name="contact_information"
      >
        <div class="grid grid-cols-8 gap-x-4">
          <base-phone-input v-model="model.phone_1"
                            :label="$t('Phone #1')"
                            :placeholder="$t('Phone #1')"
                            class="col-span-8 md:col-span-2"
          />
          <base-phone-input v-model="model.phone_2"
                            :label="$t('Phone #2')"
                            :placeholder="$t('Phone #2')"
                            class="col-span-8 md:col-span-2"
          />
          <div class="col-span-8 md:col-span-2">
            <base-switch v-model="model.receive_checks_via_email"
                         :label-info="$t('Receive checks via email')"
                         id="receive_checks_via_email"
            />
          </div>
        </div>
      </base-collapse-section>
    </el-collapse>
    <cost-center-defaults
        v-model="model"
        class="col-span-8"
    />
    <address-list :title="$t('Employee Address')"
                  :entity-id="model.id"
                  :data="addresses"
                  :resource="model"
                  class="col-span-8 mt-4"
                  ref="addressList"
                  entity="employees"
                  @refresh="$emit('re-fetch-employee', model.id)"
    />
    <employee-users-list :employee-id="model.id"
                         :data="users"
                         class="col-span-8 mt-6"
                         @remove-user="$emit('re-fetch-employee', model.id)"
    />
    <div class="col-span-8 my-4">
      <el-collapse v-model="activeGroups">
        <base-collapse-section key="work-permit"
                               :title="$t(`Work Permit & Driver's License`)"
                               name="work-permit">
          <div class="grid grid-cols-2 gap-x-4">
            <employee-work-permits
                v-model="model.work_permits"
                class="col-span-2 md:col-span-1"
                id="work-permits"
            />
            <employee-driver-licenses
                v-model="model.driver_licenses"
                class="col-span-2 md:col-span-1"
                id="driver-licenses"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="dates"
                               :title="$t('Dates')"
                               name="dates">
          <div class="grid grid-cols-8 gap-x-4">
            <base-date-picker v-model="model.dob"
                              :label="$t('Birth Date')"
                              :placeholder="$t('Birth Date')"
                              id="dob"
                              class="col-span-8 md:col-span-2"
            />
            <base-date-picker v-model="model.hire_date"
                              :label="$t('Hire Date')"
                              :placeholder="$t('Hire Date')"
                              id="hire_date"
                              class="col-span-8 md:col-span-2"
            />
            <base-date-picker v-model="model.term_date"
                              :label="$t('Termination Date')"
                              :placeholder="$t('Termination Date')"
                              id="termination_date"
                              class="col-span-8 md:col-span-2"
            />
            <base-date-picker v-model="model.other_date"
                              :label="$t('Other Date')"
                              :placeholder="$t('Other Date')"
                              id="other_date"
                              class="col-span-8 md:col-span-2 xl:col-span-1"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="additional_information"
                               :title="$t('Additional Information')"
                               name="additional_information"
        >
          <div class="grid grid-cols-8 gap-4">
            <worker-comp-rate-select v-model="model.workers_comp_rate_code"
                                     :add-entity="true"
                                     rules="required"
                                     class="col-span-8 md:col-span-2"
            />
            <gen-liability-rate-select v-model="model.gen_liability_rate_code"
                                       clearable
                                       class="col-span-8 md:col-span-2"
            />
            <union-select v-model="model.union_id"
                          :add-entity="false"
                          :label="$t('Union Code')"
                          :name="$t('Union Code')"
                          id="union_id"
                          class="col-span-8 md:col-span-2"
                          clearable
            />
            <craft-select v-model="model.craft_code_id"
                          :label="$t('Craft Code')"
                          :name="$t('Craft Code')"
                          :add-entity="false"
                          :display-level="true"
                          :rules="model.union_id ? 'required': ''"
                          id="craft_code_id"
                          class="col-span-8 md:col-span-2 xl:col-span-1"
                          clearable
            />
            <department-select
              v-model="model.department_id"
              :label="$t('Department')"
              :name="$t('Department')"
              :add-entity="true"
              id="department_id"
              class="col-span-8 md:col-span-2 xl:col-span-1"
              clearable
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="job_overrides"
                               :title="$t('Allow Job Override')"
                               name="job_overrides"
        >
          <div class="mb-6">
            <base-checkbox v-model="model.job_can_override_workers_comp"
                           :label="$t('Job Override Worker Comp Code')"
                           id="job_can_override_workers_comp"
            />
            <base-checkbox v-model="model.job_can_override_gen_liability"
                           :label="$t('Job Override Gen Liability Code')"
                           id="job_can_override_gen_liability"
            />
            <base-checkbox v-model="model.job_can_override_union"
                           :label="$t('Job Override Union Code')"
                           id="job_can_override_union"
            />
            <base-checkbox v-model="model.on_certified_payroll"
                           :label="$t('On Certified Payroll')"
                           id="on_certified_payroll"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section :title="$t('W-4 Form')"
                               key="w-4_fields"
                               name="w-4_fields">
          <div class="grid grid-cols-8 gap-4">
            <base-select v-model="model.federal_status"
                         :options="federalStatusOptions"
                         :label="$t('Federal Status')"
                         :name="$t('Federal Status')"
                         :placeholder="$t('Federal Status')"
                         id="federal_status"
                         rules="required"
                         class="col-span-8 md:col-span-2"
            />
            <base-select v-model="model.fit_adjustment_type"
                         :options="taxAdjustmentOptions"
                         :label="$t('Special Tax Adjustment')"
                         :name="$t('Special Tax Adjustment')"
                         :placeholder="$t('Special Tax Adjustment')"
                         id="fit_adjustment_type"
                         class="col-span-8 md:col-span-2 xl:col-span-1"
                         @change="onAdjustmentTypeChange"
            />
            <base-input v-model="model.fit_adjustment_rate"
                        :label="$t('Adjustment')"
                        :placeholder="$t('Adjustment')"
                        :min="0"
                        :step="0.01"
                        :max="adjustmentRateFormat === 'percent' ? 100 : 99999.99"
                        :disabled="model.fit_adjustment_type === NO_STATE_WITHHOLDING_TAX"
                        id="fit_adjustment_rate"
                        type="number"
                        :format="adjustmentRateFormat"
                        :rules="adjustmentRateRules"
                        class="col-span-8 md:col-span-2"
            />
            <div class="col-span-8">

            </div>
            <div class="col-span-8 md:col-span-2">
              <base-checkbox v-model="model.is_old_w4"
                             :label="$t('Old W-4 Form')"
                             id="is_old_w4"
              />
              <base-checkbox v-if="!model.is_old_w4"
                             v-model="model.is_step_2"
                             :label="$t('W-4 2(c) Checkbox')"
                             id="is_step_2"
              />
            </div>
            <template v-if="model.is_old_w4">
              <div class="col-span-8 md:col-span-2 xl:col-span-2">
                <base-input v-model="model.allowances"
                            :label="$t('Federal Allowances')"
                            :name="$t('Allowances')"
                            :placeholder="$t('Federal Allowances')"
                            :min="0"
                            :step="1"
                            :max="99"
                            id="allowances"
                            type="number"
                            rules="max_value:99999|min_value:0"
                />
              </div>
            </template>
            <template v-if="!model.is_old_w4">
              <base-input v-model="model.dependents_amount"
                          :label="$t('Claim Dependents')"
                          :name="$t('Claim Dependents')"
                          :placeholder="$t('Claim Dependents')"
                          :min="0"
                          :step="0.01"
                          :max="99999.99"
                          id="dependents_amount"
                          type="number"
                          format="price"
                          rules="max_value:99999.99|min_value:0"
                          class="col-span-8 md:col-span-2 xl:col-span-1"
              />
              <base-input v-model="model.other_income_amount"
                          :label="$t('Other income (not from jobs) 4(a)')"
                          :name="$t('Other income (not from jobs)')"
                          :placeholder="$t('Other income (not from jobs)')"
                          :min="0"
                          :step="0.01"
                          :max="9999999.99"
                          id="other_income_amount"
                          type="number"
                          format="price"
                          rules="max_value:9999999.99|min_value:0"
                          class="col-span-8 md:col-span-2"
              />
              <base-input v-model="model.deductions_amount"
                          :label="$t('Deductions 4(b)')"
                          :name="$t('Deductions')"
                          :placeholder="$t('Deductions')"
                          :min="0"
                          :step="0.01"
                          :max="99999.99"
                          id="deductions_amount"
                          type="number"
                          format="price"
                          rules="max_value:99999.99|min_value:0"
                          class="col-span-8 md:col-span-2 xl:col-span-1"
              />
              <base-input v-model="model.withholding_amount"
                          :label="$t('Extra Withholding 4(c)')"
                          :name="$t('Extra Withholding')"
                          :placeholder="$t('Extra Withholding')"
                          :min="0"
                          :step="0.01"
                          :max="99999.99"
                          id="withholding_amount"
                          type="number"
                          format="price"
                          rules="max_value:99999.99|min_value:0"
                          class="col-span-8 md:col-span-2 xl:col-span-1"
              />
            </template>
          </div>
          <p class="text-base font-bold leading-8 text-gray-800">{{ $t('Subject to ') }}</p>
          <div class="col-span-8">
            <base-checkbox v-model="model.subject_to_medicare_withholding"
                           id="medicare_withholding"
                           :label="$t('Medicare Withholding')"
            />
            <base-checkbox v-model="model.subject_to_social_security_withholding"
                           :label="$t('Social Security Withholding')"
                           id="social_security_withholding"
            />
            <base-checkbox v-model="model.subject_to_federal_unemployment"
                           :label="$t('Federal Unemployment')"
                           id="federal_unemployment"
            />
          </div>
        </base-collapse-section>
      </el-collapse>
    </div>
    <template #extra-buttons-right>
      <base-tooltip :disabled="!!(!model.user_id && model.email)"
                    :content="$t(`This employee either doesn't have an email or already has an attached user.`)"
      >
        <base-submit-button class="mr-2"
                            variant="primary-light"
                            :loading="createUsersLoading"
                            :disabled="!!(model.user_id || !model.email)"
                            @click="onAttachUser"
        >
          <IconAdd class="w-5 h-5 mr-2"/>
          {{ $t('Save & Create User') }}
        </base-submit-button>
      </base-tooltip>
    </template>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import cloneDeep from 'lodash/cloneDeep'
  import SsnInput from '@/components/form/SsnInput'
  import { accountRestrictions } from '@/enum/account'
  import AddressList from '@/modules/common/components/AddressList'
  import CraftSelect from '@/components/select/entities/CraftSelect'
  import CostCenterDefaults from '@/components/common/CostCenterDefaults'
  import EmployeeUsersList from '@/modules/payroll/components/EmployeeUsersList'
  import { costCenterTypes, setTypeSources } from '@/components/grid-table/utils/cost-center'
  import EmployeeWorkPermits from '@/modules/payroll/components/employee/EmployeeWorkPermits'
  import EmployeeDriverLicenses from '@/modules/payroll/components/employee/EmployeeDriverLicenses'
  import { employeeStatuses, federalFilingStatuses, minorityTypes, payFrequencies, taxAdjustments, } from '@/enum/enums'
  import { generateCodeFromName } from '@/utils/generateCodeFromName'
  import {
    federalStatusOptions,
    minorityOptions,
    payFrequencyOptions,
    sexOptions,
    statusOptions,
    taxAdjustmentOptions,
  } from "@/modules/payroll/utils/employeeUtils";
  import { globalResources, RestifyResources } from "@/components/form/util";

  export default {
    inheritAttrs: false,
    components: {
      SsnInput,
      AddressList,
      CraftSelect,
      EmployeeUsersList,
      CostCenterDefaults,
      EmployeeWorkPermits,
      EmployeeDriverLicenses,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      layout: {
        type: String,
        default: 'modal',
      },
    },
    data() {
      const isBiWeekly = this.$settings(this.$modules.PR, 'is_biweekly_payroll')
      let pay_frequency = payFrequencies.WEEKLY
      if (isBiWeekly) {
        pay_frequency = payFrequencies.BI_WEEKLY
      }
      return {
        globalResources,
        RestifyResources,
        loading: false,
        createUsersLoading: false,
        employeeStatuses,
        payFrequencies,
        suiSdiState: {},
        showStateSelect: true,
        model: {
          id: '',
          user_id: '',
          code: '',
          identification_code: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          suffix: '',
          status: employeeStatuses.HOURLY,
          pay_frequency,
          email: '',
          ssn: null,
          phone_1: '',
          phone_2: '',
          minority: minorityTypes.None,
          sex: 'M',
          withholding_state_id: '',
          withholding_local_id: '',
          workers_comp_rate_code: '',
          gen_liability_rate_code: '',
          union_id: '',
          department_id: '',
          sui_sdi_state_id: '',
          dept_code: '',
          work_permits: [],
          driver_licenses: [],
          dob: '',
          hire_date: '',
          term_date: '',
          other_date: '',
          has_pension_plan: false,
          job_can_override_workers_comp: true,
          job_can_override_gen_liability: true,
          job_can_override_union: true,
          craft_code_id: undefined,
          on_certified_payroll: true,
          salary_amount: 0,
          hourly_rate: 0,
          receive_checks_via_email: false,
          payroll_consent_token: '',
          payroll_consent_confirmed_at: '',
          payroll_consent_sent_at: '',
          payroll_consent_optout_at: '',
          subject_to_social_security_withholding: true,
          subject_to_medicare_withholding: true,
          subject_to_federal_unemployment: true,
          has_medicare_withholding: false,
          has_federal_unemployment: false,
          has_social_security_withholding: false,
          federal_status: federalFilingStatuses.SINGLE,
          is_old_w4: false,
          allowances: 0,
          is_step_2: false,
          dependents_amount: 0,
          other_income_amount: 0,
          deductions_amount: 0,
          withholding_amount: 0,
          fit_adjustment_type: taxAdjustments.NO_ADJUSTMENT,
          fit_adjustment_rate: 0,
          paper_check_min_pay_amount: 0,

          cost_center: costCenterTypes.GeneralAndAdministrative,
          account: undefined,
          subaccount: undefined,
          source_id: undefined,
          type_id: undefined,
          addl_source_id: undefined,
        },
        activeGroups: ['codes', 'dates', 'w-4_fields', 'additional_information', 'job_overrides', 'w-4_fields', 'personal_information', 'pay_information', 'contact_information'],
        NO_STATE_WITHHOLDING_TAX: 'no-adjustment',
        taxAdjustmentOptions,
        statusOptions,
        payFrequencyOptions,
        minorityOptions,
        federalStatusOptions,
        sexOptions,
        addresses: [],
        users: [],
        accountsFilter: [accountRestrictions.RESTRICTION_NO, accountRestrictions.RESTRICTION_NON_JOB],
      }
    },
    computed: {
      isBiWeeklyPayroll() {
        return this.$settings(this.$modules.PR, 'is_biweekly_payroll')
      },
      showBiWeeklyHourlyWarning() {
        return this.isBiWeeklyPayroll && this.model.pay_frequency !== payFrequencies.BI_WEEKLY && this.model.status === employeeStatuses.HOURLY
      },
      showBiWeeklySalaryWarning() {
        return this.isBiWeeklyPayroll && this.model.pay_frequency === payFrequencies.WEEKLY && this.model.status === employeeStatuses.SALARY
      },
      adjustmentRateRules() {
        if (this.adjustmentRateFormat === 'percent') {
          return 'max_value:100|min_value:0'
        }
        return 'max_value:99999.99|min_value:0'
      },
      adjustmentRateFormat() {
        const percentTypes = [
          taxAdjustments.ADDITIONAL_TAX_PERCENTAGE,
          taxAdjustments.FIXED_PERCENTAGE,
          taxAdjustments.ADDITIONAL_GROSS_PERCENTAGE,
        ]
        if (percentTypes.includes(this.model.fit_adjustment_type)) {
          return 'percent'
        }
        return 'price'
      }
    },
    methods: {
      onAdjustmentTypeChange(type) {
        if (type !== taxAdjustments.NO_ADJUSTMENT) {
          return
        }
        this.model.fit_adjustment_rate = 0
      },
      onNameBlur() {
        if (this.data.id) {
          return
        }

        const name = `${this.model.last_name || ''} ${this.model.first_name || ''}`
        this.model.code = generateCodeFromName(name)
      },
      async onSubmit(goToNext = true, attachUser = false) {
        try {
          this.$set(this.data, 'loading', true)
          this.loading = !this.createUsersLoading

          this.model = setTypeSources(this.model)

          const requestData = cloneDeep(this.model)

          if (!requestData.ssn) {
            delete requestData.ssn
          }
          if (this.model.id) {
            const { data } = await axios.put(`/restify/employees/${this.model.id}`, requestData)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Employees,
              resourceId: data.id,
              isEdit: true
            })
            if (goToNext) {
              this.$emit('re-fetch-employee', this.model.id, () => {
                this.goToNext()
              })
            }
            if (attachUser) {
              this.$emit('attach-user', requestData)
            }
            this.$success(this.$t('Employee updated successfully'))

            this.$emit('save', data)
            this.$emit('refresh', data.id)
          } else {
            const { data } = await axios.post('/restify/employees', requestData)
            this.$success(this.$t('Employee created successfully'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Employees,
              resourceId: data.id,
            })

            this.$emit('refresh-table')
            this.$emit('save', data)
          }
        } catch (err) {
          this.$error(this.$t('Could not update the employee information'))
        } finally {
          this.$set(this.data, 'loading', false)
          this.loading = this.createUsersLoading = false
        }
      },

      goToNext() {
        this.$emit('on-next')
      },
      async onAttachUser() {
        this.createUsersLoading = true
        await this.onSubmit(false, true)
      },
      async onChangeWithholdingState(state) {
        if (this.model.sui_sdi_state_id) {
          return
        }

        this.showStateSelect = false

        this.suiSdiState = state
        this.model.sui_sdi_state_id = state?.id

        await this.$nextTick(() => {
          this.showStateSelect = true
        })
      },
      onStatusChange(status) {
        if (![employeeStatuses.SALARY, employeeStatuses.HOURLY].includes(status)) {
          return
        }

        if (!this.model.id) {
          this.model.salary_amount = this.model.hourly_rate = 0
          return
        }

        if (status === employeeStatuses.SALARY) {
          this.model.salary_amount = this.get(this.data, 'attributes.salary_amount', 0)
          this.model.hourly_rate = 0
        } else {
          this.model.hourly_rate = this.get(this.data, 'attributes.hourly_rate', 0)
          this.model.salary_amount = 0
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(employee) {
          if (!employee?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...employee.attributes,
          }
          this.users = employee?.relationships?.user ? [employee?.relationships?.user] : []

          this.addresses = employee?.relationships?.addresses || []
        },
      },
    },
  }
</script>
<style lang="scss">
  .employee-form {
    table {
      .td-select,
      .th-select {
        @apply pl-3 pr-0;
      }
    }

    .el-collapse-item {
      .el-collapse-item__header {
        @apply text-base px-2;
      }

      .el-collapse-item__content {
        @apply pb-0;
      }
    }
  }
</style>
