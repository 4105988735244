<template>
  <ProofListing
      entity="timecard-batch"
      :url="url"
      :show-post="false"
      printLocal
      @data-fetch="data = $event"
  >
    <template #header>
      <div class="hidden print:block font-mono font-medium text-gray-800 text-xs -mt-1.5">
        <span>{{ $t('Period End Date:') }}</span>
        <span>{{ $formatDate(currentResource.period_end_date, 'MM/dd/yyyy', true) }}</span>
      </div>
    </template>
    <template #default="{loading}">
      <AgDataTable
        :data="flatData"
        :columns="columns"
        :data-loading="loading"
        :pagination="false"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :groupIncludeTotalFooter="true"
        :suppressAggFuncInHeader="true"
        :groupRowRendererParams="groupRowRendererParams"
        :auto-group-column-def="autoGroupColumnDef"
        :compact="true"
        :no-borders="true"
        groupDisplayType="groupRows"
        dom-layout="autoHeight"
      />
      <div class="mt-8 break-inside-avoid">
        <h5 class="form-section-title">
          {{ $t('Distribution Summary Of Hours & Special Pay') }}
        </h5>
        <AgDataTable
          :data="data.summary"
          :columns="summaryColumns"
          :data-loading="loading"
          :pagination="false"
          :compact="true"
          :no-borders="true"
          dom-layout="autoHeight"
        />
      </div>
    </template>
  </ProofListing>
</template>
<script>
  import { orderBy } from 'lodash'
  import i18n from "@/i18n";
  import PostDialog from "@/components/common/modal/PostDialog";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListingCostTypeSummary from "@/modules/common/components/ProofListingCostTypeSummary";
  import TimecardProofGroupRow from "@/modules/payroll/components/timecard/TimecardProofGroupRow.vue";

  export default {
    components: {
      PostDialog,
      ProofListing,
      TimecardProofGroupRow,
      ProofListingAccountSummary,
      ProofListingCostTypeSummary,
    },
    data() {
      return {
        loading: false,
        data: {
          timecards: [],
          summary: [],
        },
      }
    },
    computed: {
      currentResource() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      url() {
        return `/restify/timecard-batches/${this.$route.params.id}/actions?action=create-timecard-batch-proof-listing-by-employee`
      },
      flatData() {
        const allEntries = []
        this.data.timecards.forEach(timecard => {
          timecard?.entries.forEach(entry => {
            allEntries.push({
              id: crypto.randomUUID(),
              ...entry,
              employee: timecard.employee,
              timecard: timecard.timecard,
              bank: timecard.bank,
              totals: timecard.totals,
            })
          })
        })
        return orderBy(allEntries, 'employee.code')
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'TimecardProofGroupRow',
          suppressCount: true,
        }
      },
      autoGroupColumnDef() {
        return {
          minWidth: 300,
          cellRendererParams: {
            footerValueGetter: (params) => {
              const isRootLevel = params.node.level === -1;
              if (isRootLevel) {
                return 'Grand Total';
              }
              return `Sub Total (${params.value})`;
            },
          },
        }
      },
      columns() {
        return [
          {
            headerName: i18n.t('Employee'),
            field: 'employee.code',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: i18n.t('Day'),
            field: 'day',
            minWidth: 20,
            maxWidth: 50,
            cellClass: params => {
              if (!params.data) {
                return 'absolute !overflow-visible z-10'
              }
            },
            valueFormatter: (params) => {
              if (params.data) {
                return params.data.value
              }
              const isRootLevel = params.node.level === -1;
              if (isRootLevel) {
                return this.$t('Batch Totals:')
              }
              return this.$t('Employee Totals:')
            },
          },
          {
            headerName: this.$t('Job/EQP/WO'),
            field: 'source.code',
            minWidth: 70,
            maxWidth: 120,
          },
          {
            headerName: i18n.t('Type'),
            field: 'cost_center',
            minWidth: 55,
            maxWidth: 70,
          },
          {
            headerName: this.$t('Phase'),
            field: 'addl_source.phase_code',
            minWidth: 60,
            maxWidth: 70,
          },
          {
            headerName: this.$t('Code'),
            field: 'addl_source.cost_code',
            minWidth: 50,
            maxWidth: 70,
            valueFormatter: params => {
              const hasCode = params.data?.addl_source?.code
              if (hasCode) {
                return params.data?.addl_source?.code
              }
              return params.data?.addl_source?.cost_code
            }
          },
          {
            headerName: this.$t('Chg Ord'),
            field: 'addl_source.change_order',
            minWidth: 30,
            maxWidth: 50,
          },
          {
            headerName: this.$t('Regular'),
            children: [
              {
                headerName: this.$t('Hrs'),
                field: 'regular_hours',
                minWidth: 60,
                maxWidth: 80,
                aggFunc: 'sum',
                component: 'FormattedHours',
              },
              {
                headerName: this.$t('Rate'),
                field: 'regular_rate',
                component: 'FormattedPrice',
                minWidth: 65,
                maxWidth: 75,
                cellRendererParams: {
                  maximumFractionDigits: 3,
                },
              }
            ]
          },
          {
            headerName: this.$t('Overtime'),
            children: [
              {
                headerName: this.$t('Hrs'),
                field: 'overtime_hours',
                minWidth: 60,
                maxWidth: 80,
                aggFunc: 'sum',
                component: 'FormattedHours',
              },
              {
                headerName: this.$t('Rate'),
                field: 'overtime_rate',
                component: 'FormattedPrice',
                minWidth: 65,
                maxWidth: 75,
                cellRendererParams: {
                  maximumFractionDigits: 3,
                },
              }
            ]
          },
          {
            headerName: this.$t('Premium'),
            children: [
              {
                headerName: this.$t('Hrs'),
                field: 'premium_hours',
                minWidth: 60,
                maxWidth: 80,
                aggFunc: 'sum',
                component: 'FormattedHours',
              },
              {
                headerName: this.$t('Rate'),
                field: 'premium_rate',
                component: 'FormattedPrice',
                minWidth: 65,
                maxWidth: 75,
                cellRendererParams: {
                  maximumFractionDigits: 3,
                },
              }
            ]
          },
          {
            headerName: this.$t('Special Pay/Ded'),
            children: [
              {
                headerName: this.$t('Code'),
                field: 'special_source.code',
                minWidth: 60,
                maxWidth: 100,
                cellClass: params => {
                  if (!params.data) {
                    return 'absolute !overflow-visible z-10'
                  }
                },
                valueFormatter: (params) => {
                  if (params.data) {
                    return params.data.value
                  }
                  const aggData = params?.node?.aggData || {}
                  const { regular_hours, overtime_hours, premium_hours } = aggData
                  const totalHours = regular_hours + overtime_hours + premium_hours

                  return this.$t(`Total Hours:   ${this.formatHours(totalHours)}`)
                },
              },
              {
                headerName: this.$t('Units'),
                field: 'units',
                minWidth: 50,
                maxWidth: 70,
              },
              {
                headerName: this.$t('Rate'),
                field: 'special_rate',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                  maximumFractionDigits: 3,
                },
                minWidth: 65,
                maxWidth: 80,
              }
            ]
          },
          {
            headerName: this.$t('Union'),
            field: 'union.code',
            minWidth: 50,
            maxWidth: 70,
          },
          {
            headerName: this.$t('Craft'),
            field: 'craft_code.code',
            minWidth: 60,
            maxWidth: 80,
            valueFormatter: (params) => {
              if (!params.data) {
                return
              }
              const { code, level } = params.data?.craft_code || {}
              if (!code && !level) {
                return ''
              }
              return `${code} / ${level}`
            }
          },
          {
            headerName: this.$t('State'),
            field: 'state.code',
            minWidth: 50,
            maxWidth: 60,
          },
          {
            headerName: this.$t('Work Comp'),
            field: 'workers_comp_rate_code',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            headerName: this.$t('Gen Liab'),
            field: 'gen_liability_rate_code',
            minWidth: 50,
            maxWidth: 60,
          },
          {
            headerName: this.$t('Acc'),
            field: 'account',
            minWidth: 70,
            maxWidth: 100,
            component: 'AccountLink',
            cellRendererParams: {
              showDescription: false,
            }
          },
        ]
      },
      summaryColumns() {
        return [
          {
            headerName: this.$t('Job Eqp/Wo'),
            field: 'code',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            headerName: this.$t('G/L Account'),
            field: 'account',
            minWidth: 50,
            maxWidth: 70,
            component: 'AccountLink',
            cellRendererParams: {
              showDescription: false,
            }

          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            minWidth: 150,
            maxWidth: 300,
          },
          {
            headerName: this.$t('Reg Hours'),
            field: 'regular_hours',
            minWidth: 70,
            maxWidth: 100,
            component: 'FormattedHours',
          },
          {
            headerName: this.$t('Ovt Hours'),
            field: 'overtime_hours',
            minWidth: 70,
            maxWidth: 100,
            component: 'FormattedHours',
          },
          {
            headerName: this.$t('Prm Hours'),
            field: 'premium_hours',
            minWidth: 70,
            maxWidth: 100,
            component: 'FormattedHours',
          },
          {
            headerName: this.$t('Salary'),
            field: 'salary_units',
            minWidth: 60,
            maxWidth: 80,
            valueFormatter: params => {
              return params.data.value || ''
            }
          },
          {
            headerName: this.$t('Special Pay/Equipment/Material '),
            field: 'special_sources',
            minWidth: 100,
            maxWidth: 500,
            valueFormatter: params => {
              let value = params.data.special_sources
              if (!value) {
                return
              }
              value = Object.values(value)
              return value.map(specialSource => {
                return `${specialSource.code}: ${specialSource.units}`
              }).join(', ')
            }
          }
        ]
      }
    },
    methods: {
      formatHours(value) {
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        return formatter.format(value)
      },
      async getBatch() {
        await this.$store.dispatch('payroll/getTimecardBatch', this.$route.params.id)
      }
    },
    mounted() {
      this.getBatch()
    }
  }
</script>
