<template>
  <settings-form
    :fields="fields"
    :module-name="moduleName"
    :authorized-to-collapse="true"
    :before-init-model="beforeInitModel"
    grid-classes="grid grid-cols-1"
    @cancel="$router.push('/payroll/timesheets/list')"
  >
    <template #content="{model}">
      <div class="grid grid-cols-6 gap-x-3 px-4 mb-4">
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_work_orders"
                       :label-info="$t('Enable Work Orders')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_equipment"
                       :label-info="$t('Enable Equipment')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_materials"
                       :label-info="$t('Enable Materials')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_pay_codes"
                       :label-info="$t('Enable Pay Codes')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseInput
            :label="$t('Expand Payroll Information')"
            :tip="$t('Expand Payroll Information by default when adding or editing timesheets.')"
          >
            <template #container>
              <BaseSwitch
                v-model="model.show_craft_code_details"
              />
            </template>
          </BaseInput>
        </div>
      </div>
    </template>
    <template #default="{model}">
      <div class="my-4">
        <h4 class="section-title">
          {{ $t('Business Hours & Overtime') }}
        </h4>
        <div class="grid grid-cols-6 gap-x-3 mt-4">
          <div class="col-span-3 lg:col-span-2">
            <base-select
              :value="getWorkingDays(model)"
              :options="weekDays"
              :label="$t('Working Days')"
              multiple
              @change="model.working_days = $event"
            />
          </div>
          <div class="col-span-3 lg:col-span-2">
            <base-time-picker
              :value="getWorkingSchedule(model)"
              :label="$t('Working Schedule')"
              is-range
              range-separator="To"
              start-placeholder="Start time"
              end-placeholder="End time"
              format="HH:mm"
              @input="onWorkingScheduleChange(model, $event)"
            />
          </div>
          <div class="col-span-6"></div>
          <div class="col-span-3 lg:col-span-1">
            <base-switch
              v-model="model.has_overtime"
              :label-info="$t('Enable Overtime Hours')"
              @change="onHasOvertimeChange($event, model)"
            />
          </div>
          <div class="col-span-3 lg:col-span-1">
            <base-switch
              v-model="model.has_premium"
              :label-info="$t('Enable Premium Hours')"
              @change="onHasPremiumChange($event, model)"
            />
          </div>
          <div class="col-span-6 mb-4"></div>
            <div class="col-span-3 lg:col-span-1">
              <base-input
                v-model="model.regular_hours_per_day"
                :label="$t('Reg Hours per Day')"
                :name="$t('Reg Hours per Day')"
                type="number"
              >
                <template #suffix>
                  {{ $t('hrs/day') }}
                </template>
              </base-input>
            </div>
            <div class="col-span-3 lg:col-span-1">
              <base-input
                v-model="model.regular_hours_per_week"
                :label="$t('Reg Hours per Week')"
                :name="$t('Reg Hours per Week')"
                :suffix="$t('hrs/week')"
                type="number"
              >
                <template #suffix>
                  {{ $t('hrs/week') }}
                </template>
              </base-input>
            </div>
            <div class="col-span-3 lg:col-span-1">
              <base-input
                v-model="model.overtime_hours_per_day"
                :label="$t('OVT Hours per day')"
                :name="$t('OVT Hours per day')"
                :disabled="!model.has_overtime"
                type="number"
              >
                <template #suffix>
                  {{ $t('hrs/day') }}
                </template>
              </base-input>
            </div>
          <div class="col-span-3 lg:col-span-1">
            <base-select
              v-model="model.weekend_hours"
              :label="$t('Weekend hours as')"
              :name="$t('Weekend hours as')"
              :disabled="!model.has_overtime && !model.has_premium"
              :options="weekendHourOptions"
            />
          </div>
          <div class="col-span-3 lg:col-span-1">
            <base-input
              v-model="model.lunch_duration"
              :label="$t('Lunch Duration')"
              :name="$t('Lunch Duration')"
              type="number"
            >
              <template #suffix>
                {{ $t('minutes') }}
              </template>
            </base-input>
          </div>
          <div class="col-span-3 lg:col-span-1">
            <base-input
              v-model="model.break_duration"
              :label="$t('Break Duration')"
              :name="$t('Break Duration')"
              type="number"
            >
              <template #suffix>
                {{ $t('minutes') }}
              </template>
            </base-input>
          </div>
        </div>
      </div>
      <div class="my-4">
        <h4 class="section-title">
          {{ $t('Compliance Questions') }}
        </h4>
        <span class="text-gray-600 text-sm">
          {{
            $t(`Employees will be prompted to answer compliance questions when clocking out each day. Along with the indicated response type, employees will also be able to add additional text to provide detailed answers if necessary.`)
          }}
        </span>
      </div>
      <div class="flex flex-col gap-2 divide-y divide-gray-200">
        <div
          v-for="(question, index) in model.compliance" :key="question.key"
          class="py-2"
        >
          <div class="flex justify-between items-center">
            <div class="flex flex-col">
              <span class="font-semibold">
                {{ question.question }}
              </span>
              <div class="flex text-gray-600 items-center text-sm">
                <span class="min-w-[200px]">
                  {{ questionTypeMapping[question.type] }}
                </span>
                <template v-if="question.type === questionTypes.Signature">
                  <base-switch v-model="model.compliance[index].default_value"/>
                </template>
                <span v-else>
                  {{ getDefaultValue(question) }}
                </span>
              </div>
              <div v-if="question.description" class="max-w-xl">
                <span class="font-semibold text-sm">
                  {{$t('Description')}}
                </span>
                <div v-if="question.description" class="text-gray-600 text-sm">
                  {{ question.description }}
                </div>
              </div>
            </div>
            <div class="flex space-x-2">
              <TableEditButton @click="onEditQuestion(model, index)"/>
              <TableDeleteButton @click="deleteQuestion(model, index)"/>
            </div>
          </div>
        </div>
        <div class="border-t border-gray-200 mb-4 pt-4">
          <TimesheetQuestionForm
            :key="addQuestionKey"
            @submit="question => addQuestion(model, question)"/>
        </div>
        <BaseFormDialog
          v-if="showEditQuestionDialog"
          :open.sync="showEditQuestionDialog"
          :title="$t('Edit Compliance Question')"
        >
          <TimesheetQuestionForm
            :data="questionToEdit"
            @submit="question => editQuestion(model, question)"/>
        </BaseFormDialog>
      </div>
    </template>
  </settings-form>
</template>
<script>
import SettingsForm from '@/modules/common/components/SettingsForm';
import PayCodeSelect from '@/components/select/entities/PayCodeSelect';
import i18n from "@/i18n";
import { PlusIcon } from "vue-feather-icons";
import snakeCase from "lodash/snakeCase";
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import TimesheetQuestionForm from "@/modules/payroll/components/timesheets/TimesheetQuestionForm.vue";
import { weekDays } from "@/enum/enums";
import format from "date-fns/format";
import parse from "date-fns/parse";

const questionTypeMapping = {
  [questionTypes.Boolean]: i18n.t('Yes or No'),
  [questionTypes.Text]: i18n.t('Text'),
  [questionTypes.UrgentText]: i18n.t('Urgent Text'),
  [questionTypes.Signature]: i18n.t('Require Signature'),
}
const defaultCompliance = [
  {
    "key": "verify_time",
    "question": "Verify your time",
    "description": "I acknowledge that this time card accurately and completely reflects all time worked during this pay period and that no hours were worked that are not recorded on this time card.",
    "setting_title": "Require signature",
    "setting_description": "Require employees to sign clock out",
    "type": "signature",
    "default_value": true,
  },
  {
    "key": "injury",
    "question": "Where you injured?",
    "description": "",
    "type": "boolean",
    "default_value": true,
  },
  {
    "key": "breaks",
    "question": "Did you take your required breaks today?",
    "description": "",
    "type": "boolean",
    "default_value": false,
  },
]

const WeekendHourTypes = {
  Overtime: 'overtime',
  Premium: 'premium',
  Regular: 'regular',
  RegularMaxPerWeek: 'regular-up-to-max-per-week',
}
export default {
  components: {
    TimesheetQuestionForm,
    PlusIcon,
    SettingsForm,
    PayCodeSelect,
  },
  data() {
    return {
      questionTypes,
      questionTypeMapping,
      addQuestionKey: 0,
      showEditQuestionDialog: false,
      questionToEdit: null,
      weekDays,
      moduleName: 'timesheet',
      fields: [
        'compliance',
        'enable_equipment',
        'enable_work_orders',
        'enable_materials',
        'enable_pay_codes',
        'working_days',
        'business_hours_start',
        'business_hours_end',
        'break_duration',
        'lunch_duration',
        'has_overtime',
        'has_premium',
        'show_craft_code_details',
        'overtime_hours_per_day',
        'regular_hours_per_day',
        'regular_hours_per_week',
        'weekend_hours',
      ],
    }
  },
  computed: {
    weekendHourOptions() {
      return [
        {
          value: WeekendHourTypes.Overtime,
          label: this.$t('Overtime')
        },
        {
          value: WeekendHourTypes.Premium,
          label: this.$t('Premium')
        },
        {
          value: WeekendHourTypes.Regular,
          label: this.$t('Regular')
        },
        {
          value: WeekendHourTypes.RegularMaxPerWeek,
          label: this.$t('Regular up to max per week')
        }
      ]
    }
  },
  methods: {
    onHasOvertimeChange(value, model) {
      if (!value) {
        model.overtime_hours_per_day = 0
      }
      if (!value && !model.has_premium) {
        model.weekend_hours = WeekendHourTypes.Regular
      }
    },
    onHasPremiumChange(value, model) {
      if (!value && !model.has_overtime) {
        model.overtime_hours_per_day = 0
        model.weekend_hours = WeekendHourTypes.Regular
      }
    },
    getWorkingDays(model) {
      const defaultWorkingDays = [1, 2, 3, 4, 5]
      return model.working_days || defaultWorkingDays
    },
    getWorkingSchedule(model) {
      let defaultStart = new Date()
      let defaultEnd = new Date()
      defaultStart.setHours(8, 0, 0)
      defaultEnd.setHours(18, 0, 0)
      const { business_hours_start, business_hours_end } = model
      if (!business_hours_start || !business_hours_end) {
        return [defaultStart, defaultEnd]
      }
      const start = parse(business_hours_start, 'HH:mm', new Date())
      const end = parse(business_hours_end, 'HH:mm', new Date())
      return [start, end]
    },
    onWorkingScheduleChange(model, value) {
      model.business_hours_start = format(value[0], 'HH:mm')
      model.business_hours_end = format(value[1], 'HH:mm')
    },
    addQuestion(model, newQuestion) {
      const index = model.compliance.length
      const questionText = newQuestion.question + index
      newQuestion.key = snakeCase(questionText)
      model.compliance.push(newQuestion)
      this.addQuestionKey++
    },
    editQuestion(model, question) {
      const index = model.compliance.findIndex(q => q.key === question.key)
      model.compliance.splice(index, 1, question)
      this.showEditQuestionDialog = false
    },
    beforeInitModel(model) {
      if (!model.compliance?.length) {
        model.compliance = defaultCompliance
      }
      return model
    },
    getDefaultValue(question) {
      if (question.default_value === true) {
        return this.$t('Yes')
      }
      if (question.default_value === false) {
        return this.$t('No')
      }
      return question.default_value
    },
    onEditQuestion(model, index) {
      const question = model.compliance[index]
      this.questionToEdit = { ...question }
      this.showEditQuestionDialog = true
    },
    async deleteQuestion(model, index) {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete Question'),
        description: this.$t('Are you sure you want to delete this question?'),
        confirmButtonText: this.$t('Delete'),
      })
      if (!confirmed) {
        return
      }
      model.compliance.splice(index, 1)
    }
  },
}
</script>
