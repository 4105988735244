<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <base-filter-row :title="$t('Format')">

        <base-select
            v-model="model.format"
            :options="formatOptions"
            class="col-span-3"
        />

        <div v-if="employeeSummary"
             class="col-span-2 flex items-baseline">
          <span class="text-label">{{ $t('State') }}</span>
          <state-select
              v-model="model.entry_state"
              :placeholder="$t('Select State')"
              class="flex-1"
              clearable
          />
        </div>

      </base-filter-row>

      <employee-range-filter
          v-model="model"
          title="Employees"
      />

      <payroll-dates-filter
          v-model="model"
          :report-format="model.format"
      />

      <base-filter-row
        v-if="employeeSummary"
        :title="$t('Include Pending Payrolls')">

        <base-switch
          v-model="model.include_pending"
          class="col-span-3"
        />

      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <component
          :is="getReportTable"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script>
  import { EmployeeRangeFilter } from '@/components/range-filters'
  import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter'
  import { historyByEmployeeFormats, selectionDateFields } from '@/modules/payroll/components/reports/util'
  import PayrollHistoryByEmployeeReportTable
    from '@/modules/payroll/components/reports/payroll-history-by-employee/PayrollHistoryByEmployeeReportTable'
  import PayrollHistoryRegisterRecapReportTable
    from '@/modules/payroll/components/reports/payroll-history-by-employee/PayrollHistoryRegisterRecapReportTable'

  export default {
    components: {
      PayrollDatesFilter,
      EmployeeRangeFilter,
      PayrollHistoryByEmployeeReportTable,
      PayrollHistoryRegisterRecapReportTable,
    },
    data() {
      return {
        model: {
          format: historyByEmployeeFormats.PeriodSummary,
          entry_state: null,
          employee_code_from: null,
          employee_code_to: null,
          include_pending: false,
          ...selectionDateFields,
        },
        formatOptions: [
          {
            label: this.$t('Employee Period Summary'),
            value: historyByEmployeeFormats.PeriodSummary,
          },
          {
            label: this.$t('Payroll Register Recap'),
            value: historyByEmployeeFormats.RegisterRecap,
          },
        ],
      }
    },
    computed: {
      employeeSummary() {
        return this.model.format === historyByEmployeeFormats.PeriodSummary
      },
      reportNumber() {
        return this.employeeSummary ? '4872c' : '4872f'
      },
      getReportTable() {
        return this.employeeSummary ? 'PayrollHistoryByEmployeeReportTable' : 'PayrollHistoryRegisterRecapReportTable'
      },
    },
  }
</script>
