<template>
  <base-form
      :showCancel="showCancel"
      :loading="loading"
      :submit-disabled="!amountsValid"
      :focus-on-first-input="false"
      layout="modal"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <base-card class="col-span-3 mb-4 bg-white flex flex-col justify-end rounded-lg shadow">
      <div class="flex-1">
        <h5 class="font-semibold leading-snug tracking-wide truncate">{{$t("Invoice Details")}}</h5>
      </div>
      <div class="w-full flex justify-between mt-2">
        <div class="text-sm text-gray-400 font-medium">{{ $t('Invoice Open Amount') }}</div>
        <div class="text-gray-900 font-semibold text-sm">{{ $formatPrice(invoiceAmount) }}</div>
      </div>
      <div class="w-full flex justify-between mt-2 -mb-2">
        <div class="text-sm text-gray-400 font-medium">{{ $t('Invoice Discount') }}</div>
        <div class="text-gray-900 font-semibold text-sm">{{ $formatPrice(invoiceDiscount) }}</div>
      </div>
    </base-card>
    <div class="col-span-3 w-full">
      <BaseWrapper
          :entity="vendor"
          :items="vendorFieldsToDisplay"
          :footer="{}"
          title-key="name"
      />
    </div>

    <div class="col-span-12 flex justify-end my-2 btn-primary-link">
      <base-tooltip :content="$t('Add row')">
        <base-button variant="primary-link"
                     size="sm"
                     @click="addRow"
        >
          <IconAdd class="w-5 h-5"/>
          {{ $t('Add Row') }}
        </base-button>
      </base-tooltip>
    </div>
    <div
        v-for="(row, index) in model.rows"
        :key="index"
        class="col-span-12 grid grid-cols-12 gap-3 mb-5"
    >
      <hr
          v-if="index > 0"
          class="col-span-12"
      >
      <div class="flex col-span-12 grid grid-cols-12 gap-3">
        <div class="flex col-span-6 md:col-span-2">
          <base-select
              v-model="model.rows[index].type"
              :options="paymentTypeOptions"
              :label="$t('Type')"
              inline-errors
          />
        </div>
        <div v-if="hasPaymentToCopy" class="col-span-6 md:col-span-2">
          <base-button variant="white" class="mt-6" @click="copyLastPayment">
            {{ $t('Copy Last Payment') }}
          </base-button>
        </div>
        <div v-if="index > 0"
             class="flex justify-end col-span-6 md:col-span-10 btn-danger-link">
          <base-button variant="danger-link"
                       @click="model.rows.splice(index, 1)">
            <IconClose class="w-5 h-5"/>
            {{ $t('Remove Row') }}
          </base-button>
        </div>
      </div>
      <div class="col-span-12 md:col-span-2">
        <base-input
            v-model="model.rows[index].amount"
            :label="$t('Invoice Pay')"
            :name="$t('Invoice Pay')"
            :step="0.01"
            inline-errors
            id="amount"
            type="number"
            format="price"
            :rules="getAmountRules(index)"
        />
      </div>
      <div class="col-span-12 md:col-span-2">
        <base-input
            v-model="model.rows[index].discount_amount"
            :label="$t('Discount')"
            :name="$t('Discount')"
            inline-errors
            :step="0.01"
            :min="0"
            id="discount_amount"
            type="number"
            format="price"
            rules="min_value:0"
            @change="onDiscountChange(index)"
        />
      </div>
      <div
          v-if="row.type === paymentTypes.Check"
          class="col-span-12 md:col-span-2"
      >
        <vendor-select
            v-model="model.rows[index].joint_vendor_id"
            :label="$t('Joint Vendor')"
            :exclude-ids="[vendor.id]"
            inline-errors
            clearable
        />
      </div>
      <div class="col-span-8 md:col-span-1">
        <base-input
            v-model="model.rows[index].group"
            :label="$t('Group')"
            :name="$t('Group')"
            inline-errors
            id="group"
        />
      </div>
      <div class="col-span-8 md:col-span-2">
        <template v-if="isCreditCardOrManual(row)">
          <base-input
              v-model="model.rows[index].number"
              :label="row.type === paymentTypes.Manual ? $t('Check #'): $t('Transaction #')"
              :name="row.type === paymentTypes.Manual ? $t('Check #'): $t('Transaction #')"
              inline-errors
              id="paid_amount"
              rules="required"
          />
        </template>
      </div>
      <div class="col-span-12 md:col-span-2">
        <template v-if="isCreditCardOrManual(row)">
          <bank-select
              v-model="model.rows[index].bank_id"
              :label="$t('Bank #')"
              :name="$t('Bank #')"
              :used-for="BankUsedInTypes.AccountsPayable"
              inline-errors
              id="bank"
              rules="required"
          />
        </template>
      </div>
      <div class="col-span-12 md:col-span-2">
        <template v-if="isCreditCardOrManual(row)">
          <base-date-picker
              v-model="model.rows[index].date"
              :label="$t('Date')"
              :name="$t('Date')"
              inline-errors
              id="date"
              rules="required"
          />
        </template>
      </div>
    </div>
    <div v-if="!amountsValid" class="col-span-6">
      <ValidationWarning>
        {{ $t('The total paid amount or the total discount amount should not exceed the invoice amount') }}
      </ValidationWarning>
    </div>
  </base-form>
</template>
<script>
  import { emptyCheck } from '@/modules/accounts-payable/components/invoice/emptyCheck';
  import { isCreditCardOrManual, paymentTypes } from '@/modules/accounts-payable/components/invoice/paymentUtils';
  import VendorInfo from '@/modules/accounts-payable/components/vendor-history/VendorInfo.vue'
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper.vue'
  import { BankUsedInTypes } from "@/enum/enums";

  export default {
    components: { BaseWrapper, VendorInfo },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      invoice: {
        type: Object,
        default: () => ({
          attributes: {},
        }),
      },
      savedPayment: {
        type: Object,
        default: () => ({})
      },
      vendorTotal: {
        type: Number,
        default: 0,
      },
      lastPaymentRows: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        showCancel: true,
        paymentTypes,
        model: {
          rows: [
            {
              id: this.invoice.id,
              ...emptyCheck,
            },
          ],
        },
      }
    },
    computed: {
      hasPaymentToCopy() {
        return this.lastPaymentRows?.length > 0
      },
      BankUsedInTypes() {
        return BankUsedInTypes
      },
      vendor() {
        return this.get(this.invoice, 'relationships.vendor.attributes', {})
      },
      vendorFieldsToDisplay() {
        return [
          {
            title: this.$t('Payments'),
            value: this.$formatPrice(this.vendorTotal),
          },
          {
            title: this.$t('Open Payable'),
            value: this.$formatPrice(this.vendor.current_ap_amount),
          },
          {
            title: this.$t('Payment To Date'),
            value: this.$formatPrice(this.vendor.payment_to_date),
          },
        ]
      },
      paymentTypeOptions() {
        const types = [
          {
            label: this.$t('Check'),
            value: paymentTypes.Check,
          },
          {
            label: this.$t('Manual'),
            value: paymentTypes.Manual,
          },
          {
            label: this.$t('Credit Card'),
            value: paymentTypes.CreditCard,
          },
        ]
        if (this.vendor?.dd_status === 'active') {
          types.push({
            label: this.$t('Direct Deposit'),
            value: paymentTypes.DirectDeposit,
          })
        }
        return types
      },
      invoiceDiscount() {
        return this.invoice?.attributes?.discount || 0
      },
      invoiceAmount() {
        return this.invoice?.attributes?.open_amount || 0
      },
      totalPaid() {
        return this.model.rows.reduce((sum, row) => {
          return sum + row.amount
        }, 0)
      },
      totalDiscount() {
        return this.model.rows.reduce((sum, row) => {
          return sum + row.discount_amount
        }, 0)
      },
      amountsValid() {
        const haveSameSign = this.totalPaid * this.invoiceAmount >= 0
        if (!haveSameSign) {
          return false
        }

        if (this.totalPaid < 0) {
          return Math.abs(this.invoiceAmount) >= Math.abs(this.totalPaid)
        }
        return Math.abs(this.invoiceAmount) >= Math.abs(this.totalPaid) && this.totalDiscount <= this.totalPaid
      },
    },
    methods: {
      isCreditCardOrManual,
      getAmountRules(index) {
        const maxRowAmount = this.getRowMaxAmount(index)
        if (maxRowAmount >= 0) {
          return {
            max_value: maxRowAmount,
          }
        }
        return {
          min_value: maxRowAmount,
          max_value: 0,
        }
      },
      getRowMaxAmount(index) {
        let otherRowAmounts = 0
        this.model.rows.forEach((row, rowIndex) => {
          if (rowIndex !== index) {
            otherRowAmounts += (+row.amount)
          }
        })
        return this.invoiceAmount - otherRowAmounts
      },
      onDiscountChange(index) {
        const maxRowAmount = this.getRowMaxAmount(index)
        const row = this.model.rows[index]
        row.amount = maxRowAmount
      },
      addRow() {
        this.model.rows.push({
          id: this.invoice.id,
          ...emptyCheck,
          amount: this.invoiceAmount - this.totalPaid,
        })
      },
      copyLastPayment() {
        const paymentRow = this.lastPaymentRows[0]
        const openAmount = this.get(this.invoice, 'attributes.open_amount', 0)
        this.model.rows = [
          {
            ...paymentRow,
            id: this.invoice.id,
            amount: openAmount,
          }
        ]
      },
      onSubmit() {
        if (this.invoice?.attributes) {
          this.$set(this.invoice.attributes, 'paid_amount', this.totalPaid)
          this.$set(this.invoice.attributes, 'discount_amount', this.totalDiscount)
          this.$set(this.invoice.attributes, 'special_check', this.model)
        }

        this.$emit('save', {
          model: this.model,
          totalPaid: this.totalPaid,
          totalDiscount: this.totalDiscount,
          invoiceId: this.invoice.id,
        })
      },
    },
    watch: {
      invoice: {
        immediate: true,
        handler(value) {
          const specialCheck = this.invoice?.attributes?.special_check
          if (specialCheck && specialCheck.rows) {
            this.model.rows = specialCheck.rows
            return
          }
          if (this.savedPayment?.rows?.length) {
            this.model.rows = [...this.savedPayment.rows]
          } else {
            this.model.rows[0].amount = this.get(value, 'attributes.open_amount', 0)
            this.model.rows[0].discount_amount = this.get(value, 'attributes.discount_amount', 0)
          }
        },
      },
    },
  }
</script>
