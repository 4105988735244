import './styles.scss'
import JobRange from '@/components/range-filters/job-range.vue';
import AccountRange from '@/components/range-filters/account-range.vue';
import JobRangeFilter from '@/components/range-filters/JobRangeFilter.vue';
import AgedThruFilter from '@/components/range-filters/AgedThruFilter.vue';
import DateRangeFilter from '@/components/range-filters/DateRangeFilter.vue';
import PeriodYearFilter from '@/components/range-filters/PeriodYearFilter.vue';
import VendorRangeFilter from '@/components/range-filters/VendorRangeFilter.vue';
import NumberRangeFilter from '@/components/range-filters/NumberRangeFilter.vue';
import AccountRangeFilter from '@/components/range-filters/AccountRangeFilter.vue';
import LineItemRangeFilter from '@/components/range-filters/LineItemRangeFilter.vue';
import EmployeeRangeFilter from '@/components/range-filters/EmployeeRangeFilter.vue';
import CustomerRangeFilter from '@/components/range-filters/CustomerRangeFilter.vue';
import WorkOrderRangeFilter from '@/components/range-filters/WorkOrderRangeFilter.vue';
import SubAccountRangeFilter from '@/components/range-filters/SubAccountRangeFilter.vue';
import PeriodYearRangeFilter from '@/components/range-filters/PeriodYearRangeFilter.vue';
import DepartmentRangeFilter from '@/components/range-filters/DepartmentRangeFilter.vue';
import JobAndAdditionalFilters from '@/components/range-filters/JobAndAdditionalFilters.vue';
import RangeFilter from '@/components/range-filters/RangeFilter.vue';
import GenLiabilityRangeFilter from './GenLiabilityRangeFilter';
import { globalResources } from "@/components/form/util";

export interface RangeFilterType {
  component: string,
  componentParams?: Record<string, any>,
  from_key: string,
  to_key: string,
  placeholder: string,
}

interface RangeFilterConfig {
  [key: string]: RangeFilterType
}

const RangeFilterConfig: RangeFilterConfig = {
  Equipment: {
    component: 'EquipmentSelect',
    from_key: 'equipment_code_from',
    to_key: 'equipment_code_to',
    placeholder: 'Equipment',
  },
  EquipmentClass: {
    component: 'EquipmentClassSelect',
    from_key: 'equipment_class_code_from',
    to_key: 'equipment_class_code_to',
    placeholder: 'Equipment Class',
  },
  Job: {
    component: 'JobSelect',
    from_key: 'job_number_from',
    to_key: 'job_number_to',
    placeholder: 'Job',
    value_key: 'number',
  },
  WorkOrder: {
    component: 'WorkOrderSelect',
    from_key: 'work_order_number_from',
    to_key: 'work_order_number_to',
    placeholder: 'Work Order',
    value_key: 'number',
  },
  EquipmentSpecialty: {
    component: 'EquipmentSpecialtySelect',
    from_key: 'equipment_specialty_code_from',
    to_key: 'equipment_specialty_code_to',
    placeholder: 'Equip Specialty',
  },
  OCIP: {
    component: 'OcipSelect',
    from_key: 'ocip_code_from',
    to_key: 'ocip_code_to',
    placeholder: 'OCIP Code',
  },
  Union: {
    component: 'UnionSelect',
    from_key: 'union_code_from',
    to_key: 'union_code_to',
    placeholder: 'Union',
  },
  Department: {
    component: 'DepartmentSelect',
    from_key: 'department_code_from',
    to_key: 'department_code_to',
    placeholder: 'Department',
  },
  CraftCode: {
    component: 'CraftSelect',
    from_key: 'craft_code_from',
    to_key: 'craft_code_to',
    placeholder: 'Craft Code',
  },
  PayCode: {
    component: 'CodeSelect',
    componentParams: {
      codeTypes: [globalResources.PayCodes]
    },
    from_key: 'pay_code_from',
    to_key: 'pay_code_to',
    placeholder: 'Pay Code',
  },
  DeductionCode: {
    component: 'CodeSelect',
    componentParams: {
      codeTypes: [globalResources.DeductionCodes]
    },
    from_key: 'deduction_code_from',
    to_key: 'deduction_code_to',
    placeholder: 'Deduction Code',
  },
  MaterialCode: {
    component: 'MaterialSelect',
    from_key: 'material_code_from',
    to_key: 'material_code_to',
    placeholder: 'Material Code',
  },
  WithholdingState: {
    component: 'StateTaxSelect',
    from_key: 'withholding_state_code_from',
    to_key: 'withholding_state_code_to',
    placeholder: 'Withholding State',
  },
  UIState: {
    component: 'StateTaxSelect',
    from_key: 'ui_state_code_from',
    to_key: 'ui_state_code_to',
    placeholder: 'UI State',
  },
  GenLiability: {
    component: 'GenLiabilityRateSelect',
    from_key: 'general_liability_code_from',
    to_key: 'general_liability_code_to',
    placeholder: 'General Liability',
  },
  WorkerComp: {
    component: 'WorkerCompRateSelect',
    from_key: 'workers_comp_code_from',
    to_key: 'workers_comp_code_to',
    placeholder: `Worker's Comp`,
  },
}

interface PeriodYearFilterKeys {
  period_start: null | Date;
  fiscal_year_start: null | Date;
  period_end: null | Date;
  fiscal_year_end: null | Date;
}

export const periodYearFilterKeys: PeriodYearFilterKeys = {
  period_start: null,
  fiscal_year_start: null,
  period_end: null,
  fiscal_year_end: null,
}

const agedByGrouping: Record<string, any> = {
  Job: 'job',
  WorkOrder: 'work_order',
}

export {
  JobRange,
  AccountRange,
  JobRangeFilter,
  AgedThruFilter,
  DateRangeFilter,
  PeriodYearFilter,
  VendorRangeFilter,
  NumberRangeFilter,
  AccountRangeFilter,
  EmployeeRangeFilter,
  CustomerRangeFilter,
  LineItemRangeFilter,
  WorkOrderRangeFilter,
  SubAccountRangeFilter,
  PeriodYearRangeFilter,
  DepartmentRangeFilter,
  JobAndAdditionalFilters,
  RangeFilter,
  RangeFilterConfig,
  GenLiabilityRangeFilter,
  // * Common constants
  agedByGrouping,
}
