import EmptyLayout from "@/components/common/EmptyLayout";
import PurchaseOrdersLayout from "@/modules/purchasing-inventory/layout/PurchaseOrdersLayout";
import PurchaseOrdersList from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-orders-list";
import PurchaseOrdersAdd from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-orders-add";
import PurchaseOrderLayout from "@/modules/purchasing-inventory/layout/PurchaseOrderLayout";
import PurchaseOrdersEdit from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-orders-edit";
import PurchaseOrderDetails from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-order-details";
import MaterialsList from "@/modules/purchasing-inventory/pages/materials-list";
import SettingsLayout from "@/modules/purchasing-inventory/layout/SettingsLayout";
import MaterialClass from "@/modules/purchasing-inventory/pages/settings/material-class-list";
import PIJournal from "@/modules/common/components/journal/journal";
import PIJournalDetails from "@/modules/common/components/journal/journal-details";
import PurchasingInventorySettings from "@/modules/purchasing-inventory/pages/settings/purchasing-inventory-settings";
import MaterialAdd from '@/modules/purchasing-inventory/pages/material-add.vue'
import MaterialLayout from '@/modules/purchasing-inventory/layout/MaterialLayout.vue'
import MaterialEdit from '@/modules/purchasing-inventory/pages/material-edit.vue'
import MaterialDetails from '@/modules/purchasing-inventory/pages/material-details.vue'
import MaterialTransactions from "@/modules/purchasing-inventory/pages/material-transactions.vue";
import PurchaseOrderListingReport from "@/modules/purchasing-inventory/pages/reports/purchase-order-listing-report.vue";
import ReportLayout from "@/modules/purchasing-inventory/layout/ReportLayout.vue";
import MaterialTimesheets from "@/modules/purchasing-inventory/pages/material-timesheets.vue";
import ReviewPurchaseOrders from "@/modules/purchasing-inventory/pages/purchase-orders/review-purchase-orders.vue";
import MaterialPurchaseOrders from "@/modules/purchasing-inventory/pages/material-purchase-orders.vue";
import MaterialInvoices from "@/modules/purchasing-inventory/pages/material-invoices.vue";
import MaterialBillings from "@/modules/purchasing-inventory/pages/material-billings.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";

const reportRoutes = [
  {
    path: 'purchase-order-listing',
    name: 'Purchase Order Listing',
    title: 'Purchase Order Listing',
    meta: {
      permissions: ['inventory_management_reports'],
      appScreen: 'Purchase Order Listing: 78.71',
    },
    component: PurchaseOrderListingReport,
  },
]
export default [
  {
    path: '/purchasing-inventory',
    name: 'Purchasing & Inventory',
    component: EmptyLayout,
    redirect: '/purchasing-inventory/purchase-orders',
    meta: {
      isModule: true,
    },
    children: [
      {
        path: 'purchase-orders',
        name: 'Purchase Orders',
        redirect: '/purchasing-inventory/purchase-orders/open',
        description: 'Purchase Orders are a means of documenting purchase agreements for materials and services. You may use the Purchasing system to print new purchase orders or record “manual” purchase orders prepared outside of the system.',
        meta: {
          permissions: ['purchase_orders_show'],
          appScreen: 'Enter Purchase Orders: 70.10',
          status: 'pending',
        },
        component: PurchaseOrdersLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Purchase Orders',
            title: 'Pending Purchase Orders',
            description: 'Quickly access and manage your pending purchase orders',
            meta: {
              permissions: ['purchase_orders_show'],
              appScreen: 'Enter Purchase Orders: 70.10',
              status: 'pending',
            },
            component: PurchaseOrdersList,
          },
          {
            path: 'closed',
            name: 'Paid Purchase Orders',
            title: 'Paid Purchase Orders',
            description: 'Manage Paid A/P Invoices',
            meta: {
              permissions: ['purchase_orders_show'],
              appScreen: 'Enter Purchase Orders: 70.10',
              status: 'closed',
            },
            component: PurchaseOrdersList,
          },
          {
            path: 'voided',
            name: 'Voided Purchase Orders',
            title: 'Voided Purchase Orders',
            description: 'Manage Voided Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
              appScreen: 'Enter Purchase Orders: 70.10',
              status: 'voided',
            },
            component: PurchaseOrdersList,
          },
          {
            path: 'open',
            name: 'Open Purchase Orders',
            title: 'Open Purchase Orders',
            description: 'Manage Open Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
              appScreen: 'Enter Purchase Orders: 70.10',
              status: 'open',
            },
            component: PurchaseOrdersList,
          },
          {
            path: 'all',
            name: 'All Purchase Orders',
            title: 'All Purchase Orders',
            description: 'Manage All Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
              appScreen: 'Enter Purchase Orders: 70.10',
              status: '',
            },
            component: PurchaseOrdersList,
          },
          {
            path: 'review',
            name: 'Review Purchase Orders',
            title: 'Review Purchase Orders',
            description: 'Review pending POs',
            meta: {
              permissions: ['purchase_orders_approve'],
            },
            component: ReviewPurchaseOrders,
          },
          {
            path: 'add',
            name: 'Add Purchase Orders',
            title: 'Add Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
            },
            component: PurchaseOrdersAdd,
          },
          {
            path: ':id',
            redirect: '/purchasing-inventory/purchase-orders/:id/view',
            meta: {
              permissions: ['purchase_orders_show'],
              resourceName: 'purchase-orders',
            },
            component: PurchaseOrderLayout,
            children: [
              {
                path: 'edit',
                name: 'Purchase Order Edit',
                meta: {
                  permissions: ['purchase_orders_update'],
                  appScreen: 'Enter Purchase Orders: 70.10',
                  resourceName: 'purchase-orders',
                },
                component: PurchaseOrdersEdit,
              },
              {
                path: 'view',
                name: 'Purchase Order Details',
                meta: {
                  permissions: ['purchase_orders_show'],
                  appScreen: 'Enter Purchase Orders: 70.10',
                  resourceName: 'purchase-orders',
                },
                component: PurchaseOrderDetails,
              },
            ],
          },
        ],
      },
      {
        path: 'materials',
        name: 'Materials',
        title: 'Materials',
        description: 'Create or revise Material Inventory Codes.',
        meta: {
          permissions: ['materials_show'],
          appScreen: 'Setup Materials: 70.40',
        },
        component: MaterialsList,
      },
      {
        path: 'materials/add',
        name: 'Add Material',
        title: 'Add Material',
        description: 'Setup Material information',
        meta: {
          permissions: ['materials_store'],
          appScreen: 'Setup Materials: 70.40',
        },
        component: MaterialAdd,
      },
      {
        path: 'materials/:id',
        name: 'Material',
        redirect: '/purchasing-inventory/materials',
        meta: {
          permissions: ['materials_show'],
        },
        component: MaterialLayout,
        children: [
          {
            path: 'edit',
            name: 'Material Edit',
            meta: {
              permissions: ['materials_update'],
              appScreen: 'Setup Materials: 70.40',
              resourceName: 'materials',
            },
            component: MaterialEdit,
          },
          {
            path: 'view',
            name: 'Material Details',
            meta: {
              permissions: ['materials_update'],
              appScreen: 'Setup Materials: 70.40',
              resourceName: 'materials',
            },
            component: MaterialDetails,
          },
          {
            path: 'invoices',
            name: 'Material Invoices',
            meta: {
              permissions: ['invoices_show'],
            },
            component: MaterialInvoices,
          },
          {
            path: 'billings',
            name: 'Material Billings',
            meta: {
              permissions: ['billings_show'],
            },
            component: MaterialBillings,
          },
          {
            path: 'documents',
            name: 'Material Documents',
            meta: {
              permissions: ['materials_update'],
              resourceName: RestifyResources.Material,
            },
            component: DocumentsTable,
          },
          {
            path: 'purchase-orders',
            name: 'Material Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
            },
            component: MaterialPurchaseOrders,
          },
          {
            path: 'timesheets',
            name: 'Material Timesheets',
            meta: {
              permissions: ['timesheets_approve'],
            },
            component: MaterialTimesheets,
          },
          {
            path: 'transactions',
            name: 'Material History',
            meta: {
              permissions: ['transaction_show'],
            },
            component: MaterialTransactions,
          },
        ],
      },
      {
        path: 'reports',
        name: 'IV Reports',
        redirect: '/purchasing-inventory/reports/purchase-order-listing',
        meta: {
          permissions: ['inventory_management_reports'],
        },
        component: ReportLayout,
        children: reportRoutes,
      },
      {
        path: 'settings',
        name: 'IV Settings',
        redirect: '/purchasing-inventory/settings/purchasing-inventory-settings',
        meta: {
          hideBreadCrumb: true,
          permissions: ['inventory_management_settings'],
        },
        component: SettingsLayout,
        children: [
          {
            path: 'purchasing-inventory-settings',
            name: 'Purchasing & Inventory Settings',
            title: 'Purchasing & Inventory Settings',
            meta: {
              permissions: ['inventory_management_settings'],
              appScreen: 'Initialize Purchasing System: 79.91',
            },
            component: PurchasingInventorySettings,
          },
          {
            path: 'material-classes',
            name: 'Material Classes',
            title: 'Material Classes',
            description: 'Setup Material Classes',
            meta: {
              permissions: ['material_class_show'],
              appScreen: 'Setup Material Class Codes: 79.92',
            },
            component: MaterialClass,
          },
        ],
      },
      {
        path: 'journals',
        name: 'Purchasing & Inventory Journals',
        title: 'Purchasing & Inventory Journals',
        meta: {
          permissions: ['inventory_management_reports'],
          appScreen: 'General Journal Entries: 10.40',
          module: 'purchasing-inventory',
        },
        component: PIJournal,
      },
      {
        path: 'journals/:id/view',
        name: 'View Purchasing & Inventory Journal',
        meta: {
          permissions: ['inventory_management_reports'],
          appScreen: 'General Journal Entries: 10.40',
          module: 'purchasing-inventory',
        },
        component: PIJournalDetails,
      },
    ],
  },
]
